/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.participant-permissions-body {
    margin: 0 0 !important;
    height: 80%;
    overflow-y: scroll;
    padding: $size-md;

    h2 {
        font-size: $font-xl;
        text-align: left;
        margin: $size-xs 0 $size-lg 0;
    }

    p {
        text-align: left;
    }
&__permissions-title {
    border-top: $base-border;
    padding-top: $size-lg;
    margin-top: $size-xl;
}
    .participant-permission {
        align-items: center;
        .MuiInput-root {
            width: 100%;
            .MuiSelect-standard {
                color: $color-primary;
            }
        }
    }

    .participant-card__info {
        width: unset;
    }

    .participant-card-list {
        max-height: unset;
        margin: $size-md 0;
        width: 100%;
    }

    .pending-permission-card {
        box-shadow: $banner-shadow;
        border: $base-border;
        border-radius: $basic-btn-border-radius;

        margin-bottom: $size-sm;
        padding: $size-lg 0;

        &__buttons {
            display: flex;
            justify-content: center;
            gap: $spacing-sm;
            padding: 0 $size-md;

            button {
                height: $back-btn-size;
            }
        }

        &__header {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: $spacing-sm;

            &__icon-wrap {
                width: $svg-height-xxxl;
                height: $svg-height-xxxl;
                background-color: $color-grey-neutral-100;
                border-radius: $border-circle;
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    stroke: $color-primary;
                    opacity: 1;
                    height: $svg-height-lg;
                    width: auto;
                }
            }
        }

        &__header,
        &__body {
            padding: 0 $size-lg;
        }
    }
    
}
