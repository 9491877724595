/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.show-role-screen {
    width: 100%;
    padding: $size-lg;

    .form {

        h2 {
            text-align: left;
        }
    }
}