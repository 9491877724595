/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.plan-card {
    padding: $size-lg $size-md;
    margin-bottom: $size-sm;
    border: 1px solid transparent;
    outline: $base-border;
    border-radius: $card-border-radius;
    box-shadow: none;
    font-size: $font-md;

    &__icon {
        stroke: $color-secondary;
        display: flex;
        justify-content: space-between;
        align-items: center;
        svg {
            height: $svg-height-xxl;
            width: auto;
        }
    }
    &__title {
        margin-top: $size-xs;
    }

    &__description,
    &__price-description {
        color: $color-grey-neutral-700;
    }

    &__description {
        min-height: 70px;
    }

    &__price {
        font-size: $font-xl;

        span {
            font-size: $font-md;
            color: $color-grey-neutral-700;
            margin-left: $size-xs;
        }
    }

    &__details {
        margin-top: $size-lg;

        &__workflows-limit,
        &__accounts-limit {
            padding-bottom: $size-xs;
            font-size: $font-md;
        }

        &__signatures,
        &__processes {
            h3 {
                font-size: $font-md;
            }

            p {
                font-size: 12px;
                margin-top: -$size-xs;
            }

            &__signature,
            &__process {
                display: flex;
                margin-bottom: $size-xs;
                font-size: $font-sm;

                &__icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: $svg-height-md;
                    height: $svg-height-md;
                    background-color: $color-primary;
                    border-radius: $border-circle;
                    margin-right: $size-lg;

                    svg {
                        width: auto;
                        height: auto;
                        stroke: $color-white;
                    }
                }

                &__info {
                    span {
                        display: block;
                        font-size: $font-xs;
                        color: $color-grey-neutral-700;
                        margin-top: $size-xxs;
                    }
                }
            }
        }
        &__processes {
            margin: $size-lg 0;
            &__process {
                margin-bottom: $size-md;
            }
        }
    }
    .collapse-btn {
        svg {
            stroke: $color-primary;
            height: $svg-base-height;
            width: auto;
            margin-left: $size-xs;
        }
    }

    &:hover {
        box-shadow: $workflow-button-shadow;
    }

    &.active {
        outline: none;
        border: $primary-border-2;

        .your-plan-badge {
            color: $color-white;
            background-color: $color-primary;
            padding: $size-xs $size-sm;
            border-radius: $input-border-radius;
        }
    }
}
