/**
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.contract-card {
    width: 100%;
    padding: $size-sm;
    margin-bottom: $size-sm;
    color: $color-secondary;
    border: $base-border;
    border-radius: $card-border-radius;
    box-shadow: none;

    a {
        text-decoration: none;
    }

    &__header {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;

        h2 {
            color: $color-grey-neutral-700;
            font-size: $font-md;
            margin: $size-xs;
        }

        &__chips {
            margin: $size-xxs 0;
            display: flex;
            gap: $size-xs;
            margin-bottom: $size-sm;
        }

        &__info {
            display: flex;
            align-items: center;

            .MuiAvatar-root {
                background-color: $color-primary;
                width: $avatar-md;
                height: $avatar-md;

                svg {
                    height: $svg-height-md;
                    stroke: $color-white;
                }
            }

            &__contract-owner {
                margin-left: $size-xs;
                font-size: $font-sm;
            }            
        }
    }

    &__contract-type {
        font-size: $font-lg;
        margin: $size-sm 0;
        color: $color-grey-neutral-900;
    }

    &__contract-name {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: $size-sm;
        margin: $size-md 0;

        svg {
            display: none;
            stroke: $color-primary;
        }

        h3 {
            color: $color-primary;
            text-decoration: underline;
            margin: 0;
        }
    }

    &__signatures-wrapper {
        display: flex;
        flex-direction: column;

        &__general-info {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: $size-sm;
            justify-content: space-between;
            border-top: $base-border;
            padding-top: $size-md;

            &__signing-state {
                justify-content: space-between;
                display: inline-flex;
                gap: $size-sm;
                align-items: center;

                &__signers {
                    display: inline-flex;
                    gap: $size-xs;

                    >.MuiAvatarGroup-root {
                        >.MuiAvatar-root {
                            width: $avatar-md;
                            height: $avatar-md;
                            font-size: $font-md;
                        }
                    }
                    &__signer {
                        position: relative;
                        width: $avatar-lg;
                        height: $avatar-lg;

                        .MuiAvatar-root {
                            width: $avatar-md;
                            height: $avatar-md;
                            font-size: $font-md;
                        }

                        &__pending,
                        &__signed {
                            position: absolute;
                            bottom: -4px;
                            right: -4px;
                            border-radius: 50%;
                            background-color: $color-white;
                        }

                        &__pending {
                            fill: $color-warning;
                        }

                        &__signed {
                            fill: $color-success;
                        }
                    }
                }

                &__contract-state {
                    padding: $size-xs;
                    border-radius: $basic-btn-border-radius;
                    font-size: $font-md;
                    color: $color-grey-neutral-900;
                    background-color: $color-grey-neutral-100;
                    display: inline-flex;
                    align-items: center;

                    svg {
                        stroke: $color-grey-neutral-900;
                        margin-right: $size-xxs;
                    }

                    &--all-signed {
                        color: $color-white;
                        background-color: $color-success;

                        svg {
                            stroke: $color-white;
                        }
                    }
                }

                &__expand-signers {
                    background: none;
                    border: none;

                    svg {
                        stroke: $color-primary;
                    }
                }
            }
        }

        &__signers-list {
            display: flex;
            flex-direction: column;
            gap: $size-sm;
            margin-top: $spacing-md;
            padding-top: $spacing-md;
            border-top: $base-border-light;

            &__signer {
                display: inline-flex;
                justify-content: flex-start;
                align-items: center;
                gap: $size-sm;
                margin-bottom: $size-sm;

                &__name, &__email, &__signature{
                    margin: 0 $spacing-xxxs;
                    color: $color-grey-neutral-700;
                    line-height: 1.6;
                }
                &__timeExpired {
                    color: $color-grey-neutral-700;
                    margin-left: $spacing-xxxs; 
                }
                &__name {
                    font-size: $font-md;
                }
                &__avatar {
                    position: relative;

                    &__pending,
                    &__signed {
                        position: absolute;
                        bottom: -4px;
                        right: -4px;
                        border-radius: 50%;
                        background-color: $color-white;
                    }

                    &__pending {
                        fill: $color-warning;
                    }

                    &__signed {
                        fill: $color-success;
                    }
                }
            }
        }
    }

    &__signed{
        span {
            margin: 0 $spacing-xxxs;
            color: $color-primary
        }
    }

    &__valid{
        span {
            margin: 0 $spacing-xxxs;
            color: $color-grey-neutral-700
        }
        
    }

    &__alert {
        display: flex;
        align-items: center;
        
        span{
            margin: 0 $spacing-xxxs;
            color: $color-yellow-todo-500
        }

        svg {
            stroke: $color-yellow-todo-400;
            height: $svg-height-md;
            width: auto;
        }
    }
}

@media only screen and (min-width: $breakpoint-lg) {
    .contract-card {
        &__signatures-wrapper {
            &__general-info {
                flex-direction: unset;
            }
        }
        &__header {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
        &__contract-name {
            svg {
                display: block;
            }
        }
    }
}

@media only screen and (min-width: $breakpoint-xl) {
    .contract-card {
        &__signatures-wrapper {
            &__signers-list {
                &__signer {
                    &__name, &__email, &__signature {
                        display: inline;
                        font-size: inherit;
                    }
                    &__name, &__email {
                        &::after {
                            content: "∙";
                            padding-left: $size-xxs;
                        }
            
                    }
                }
            }
        }
    }
}
