/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.workflow-card {
    display: flex;
    padding: $size-sm;
    margin-bottom: $size-sm;
    border: $base-border;
    border-radius: $card-border-radius;
    box-shadow: none;
    transition: $input-transition;

    &__info {
        width: 100%;
        font-size: $font-xl;
        color: $color-secondary;

        &__header {
            width: 100%;
            display: flex;
            justify-content: space-between;

            &__left {
                color: $color-primary;
            }

            &__right {
                button {
                    min-width: 1px;

                    .MuiAvatar-root {
                        width: 28px;
                        height: 28px;
                        font-size: $font-sm;
                        
                        &:not(.participant-avatar) {
                            padding-left: $size-xxs;
                            color: $color-secondary;
                            background-color: transparent;
                            overflow: visible;
                        }
                    }
                }
            }
        }
        
        h3.workflow-card__info__title{
            font-size: $font-md;
            margin: $size-sm 0 0;
        }
        
        &__location, &__value {
            font-size: $font-md;
            margin-bottom: $size-sm;
        }

        .progress-stepper__wrapper {
            padding: 0;
        }
    }

}

@media only screen and (min-width: $breakpoint-md) {
    .workflow-card {
        display: flex;
        flex-direction: row;
    
        &__image {
            display: block;
            width: 20%;

            .MuiAvatar-root {
                width: 100%;
                height: auto;
                img {
                    aspect-ratio: 1 / 1;
                }
            }
        }
    }
}