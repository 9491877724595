/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.usage-card {
    border: $base-border;
    border-radius: $basic-btn-border-radius;
    padding: $size-md;
    height: 100%;

    h2 {
        margin-top: 0;
        font-size: $font-lg;
    }

    &__info {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        flex-direction: column;

        &__current-available {
            font-size: $font-xl;
            span {
                color: $color-primary;
                font-size: $spacing-lg;
            }
        }

        &__details {
            display: flex;
            justify-content: flex-end;
            font-size: $font-sm;

            // Styles the gap between each element
            > *:not(:last-child)::after {
                content: "|";
                margin: 0 $size-xs;
            }
        }
    }

    &__warning {
        border-radius: $input-border-radius;
        padding: $spacing-xxs $spacing-xs;
        font-size: $font-sm;

        &--exhausted {
            background-color: $color-warning;
        }

        &--price-when-exhausted {
            background-color: $color-grey-neutral-100;
        }
    }

    a {
        font-size: $font-sm;
    }
}

@media only screen and (min-width: $breakpoint-xs) {
    .usage-card {
        &__info {
            flex-direction: row;
        }
    }
}

@media only screen and (min-width: $breakpoint-sm) {
    .usage-card {
        &__info {
            flex-direction: column;
        }
    }
}

@media only screen and (min-width: $breakpoint-lg) {
    .usage-card {
        &__info {
            flex-direction: row;
        }
    }
}
