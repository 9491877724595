/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.member-collapse {
    width: 100%;
    padding: $size-lg;
    margin-bottom: $size-lg;
    position: relative;
    background-color: $color-white;
    font-family: $font-primary;

    &__wrap {
        width: 100%;
        display: flex;
        justify-content: space-between;
        background-color: transparent;
        border: none;
        padding: 0;

        &__profile {
            display: flex;
            align-items: center;
            gap: $spacing-xs;
            font-family: $font-primary;

            &__avatar {
                .MuiAvatar-root {
                    background-color: $color-primary;
                    width: $svg-height-xxl;
                    height: $svg-height-xxl;

                    svg {
                        height: $svg-height-lg;
                        stroke: $color-white;
                    }
                }
            }

            &__info {
                &__list {
                    display: flex;
                    margin: 0;
                    padding: 0;
                    gap: $size-sm;
                    list-style-type: none;
                }

                &__name {
                    right: $size-lg;
                    font-size: $font-md;
                    margin-bottom: $size-sm;
                    text-align: left;

                    p {
                        margin: 0;
                        padding-left: $size-xxs;
                    }
                }
            }
        }

        &__transactions {
            margin-right: $size-lg;
        }
    }

    &__options {
        position: absolute;
        top: $size-sm;
        right: $size-md;
    }

    &__roles {
        border-radius: 10px;
        padding: $size-lg;
        background-color: rgba($color-grey-neutral-100, 0.3);
        margin-top: $size-md;

        &__select {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .selected {
                color: rgba($color-black, 0.4);
            }
        }
    }
}
