
/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.signer-invitation-claim-callback-screen {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-areas:
        'infoarea'
        'pdfarea';
    grid-template-rows: auto 1fr;
    max-width: $breakpoint-md;
    margin: 0 auto;

    &__contract-data {
        max-width: $breakpoint-md;
        width: 100%;
        margin: 0 auto;
        padding: $default-page-padding;
        padding-top: $size-lg;
        grid-area: infoarea;

        &__name{
            margin-top: $size-xs;
            font-size: $font-sm;
        }

        &__type{
            display: block;
            margin: $size-sm 0 $size-xs;
            font-size: $font-xl;
        }
    }

    &__blocking-overlay {
        height: 100%;
        width: calc(100% - 2.858rem);
        padding: 0 $size-lg;
        position: absolute;
        z-index: 5;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        text-align: center;

        p, h2 {
            max-width: calc(100% - $size-lg);
            text-overflow: clip;
        }

        .smart-code {
            display: inline-flex;
            justify-content: center;
        }
    
        #pin-actions {
            display: inline-flex;
            flex-direction: column;
            gap: $spacing-lg;
        }

    }

    &__pdf-viewer {
        height: 100%;
        position: relative;
        display: block;

        &__buttons {
            position: absolute;
            right: $spacing-xs;
            bottom: 40%;

            button {
                transition: transform $cubic-benzier-easing;

                
                &.active {
                    transform: translateX(-$spacing-xs);
                }
            }
            
            .MuiButtonBase-root.icon-btn, .MuiButtonBase-root.icon-btn:hover {

                svg {
                    height: $svg-height-md;
                }
            }
        }
    }
} 