/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

 .signer-invitation-layout {
    display: grid;
    grid-template-rows: auto 1fr auto; 
    grid-template-areas:
    'header'
    'pagearea'
    'footer';
    height: 100dvh;

    &__header {
        grid-area: header; 
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: $size-sm $size-lg;
        border-bottom: $base-border;
        background-color: $color-white;
    }

    &__children {
        grid-area: pagearea; 
        overflow: auto;
    }
 }

 @media only screen and (min-width: $breakpoint-lg){
    .signer-invitation-layout {

        &__header {
            padding: $default-page-padding;

        }
    }
 }


