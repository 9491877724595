/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.language-selector {
    width: 100%;

    svg {
        stroke: unset !important;
    }

    .radio-select {
        .MuiFormGroup-root {
            flex-direction: row;
            gap: $size-sm;

            .radio-select__item {
                width: max-content;
                margin-bottom: unset;

                .MuiRadio-root {
                    display: none;
                }

                .MuiFormControlLabel-root .MuiTypography-root {
                    margin-left: unset;
                }

                &.selected {
                    background-color: $color-brand-50;
                    color: $color-brand-500;
                    border: none;
                }
            }
        }
    }
}
