/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.switch {
    display: flex;
    align-items: center;
    gap: 8px;

    &__toggle {
        position: relative;
        display: inline-block;
        width: 42px;
        height: 24px;
        margin-right: 10px;

        > input[type='checkbox'] {
            display: none;
            
            &:checked + .switch__toggle__slider {
                background-color: $color-primary;
                box-shadow: 0px 4px 4px 0px #00000040 inset;

                &::before {
                    transform: translateX(100%);
                    left: 0;
                }
            }
        }

        &__slider {
            position: absolute;
            inset: 0;
            background-color: $color-grey-neutral-200;
            box-shadow: unset;
            transition: all .3s ease;
            cursor: pointer;
            border-radius: 18px;
            height: 33px;
            width: 56px;

            &::before {
                position: absolute;
                content: "";
                aspect-ratio: 1;
                left: 3px;
                bottom: 3px;
                background-color: white;
                transition: .3s;
                border-radius: 18px;
                width: 27px;
                height: 27px;
            }
        }
    }

    &__label {
        display: flex;
        flex-flow: column;

        > label,
        > span {
            font-size: 12px;
            line-height: 14px;
        }

        > label {
            color: rgba($color-black, .38);
        }
    }
}
