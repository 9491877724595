/**
 *
 * @Copyright 2025 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

 .user-card {
    display: inline-grid;
    grid-template-columns: auto max-content;
    width: 100%;
    padding: $size-md;
    position: relative;
    box-shadow: $card-shadow;
    border: $base-border;
    border-radius: $basic-btn-border-radius;
    cursor: pointer;
    transition: $input-transition; 

    &:hover {
      box-shadow: $card-shadow-strong
    }

    &__info {
        width: 100%;
        display: inline-grid;
        grid-template-columns: max-content auto;
        align-items: center;
        justify-content: start;
        gap: $spacing-lg;

        &__icon-wrap {
            width: $svg-height-xxxl;
            height: $svg-height-xxxl;
            background-color: $color-grey-neutral-100;
            border-radius: $border-circle;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                stroke: $color-grey-neutral-900;
                opacity: 1;
                height: $svg-height-lg;
                width: auto;
            }
        }

        &__details {
            overflow: hidden;

            p {
                margin: 0;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                text-align: justify;
                
            }

            p:first-of-type {
                margin-bottom: $size-sm;
            }

            &__role {
                font-size: $font-sm;
                line-height: $font-sm;
                color: $color-grey-neutral-900;
                border-radius: $font-lg;
                width: max-content;
            }
        }
    }

}

