/**
*
* @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
* Development by VOID Software, SA
*
*/

.modal-confirm {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $size-md;

    &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background-color: $color-white;
        width: 500px;
        max-height: 95vh;
        overflow-y: auto;
        position: relative;
        border-radius: $modal-border-radius;

        &__header {
            background-color: $color-white;
            border-bottom: $base-border;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: $size-md $size-lg;
            position: sticky;
            z-index: 1;
            top: 0;

            h3 {
                font-size: $font-xl;
                margin: 0;
            }

            &__close-btn {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                width: $svg-height-xxl;
                height: $svg-height-xxl;
                background-color: transparent;
                border: none;

                svg {
                    stroke: $color-secondary;
                    cursor: pointer;
                }
            }
        }

        &__content {
            padding: $modal-padding;
            width: 100%;
            white-space: break-spaces;

            .MuiTabs-flexContainer {
                justify-content: space-between;

                button {
                    text-transform: none;
                }
            }
            .MuiTabPanel-root {
                padding: $size-xl 0 0;
            }
        }

        p {
            text-align: center;
            margin-bottom: $size-md;

            &.primary {
                color: $color-primary;
            }

            &.font-xl {
                font-size: $font-xl;
            }

            &.warning-msg {
                margin-top: 0;
            }
        }

        .content-wrap {
            margin: 0 $size-md;
            text-align: center;
        }

        .MuiButtonBase-root.primary,
        .MuiButtonBase-root.primary:hover,
        .MuiButtonBase-root.secondary,
        .MuiButtonBase-root.secondary:hover {
            margin-top: $size-md;
        }

        &__buttons {
            display: flex;
        }

        .form__fields {
            label {
                display: block;
                margin-bottom: $size-xs;
            }

            .phone-input__container {
                margin-bottom: $size-lg;
            }
        }

        .buttons {
            display: flex;
            gap: $spacing-xs;
            flex-wrap: wrap;
            justify-content: center;
            margin-top: $size-xl;

            .MuiButtonBase-root.primary,
            .MuiButtonBase-root.primary:hover,
            .MuiButtonBase-root.secondary,
            .MuiButtonBase-root.secondary:hover {
                margin: $size-xxs 0;
                padding: $brand-btn-shorter-padding;
                min-width: $modal-button-line-button-width;
            }

            .inline-button {
                display: inline-flex;
                justify-content: flex-start;
                gap: $spacing-xs;
                width: 100%;
                cursor: pointer;

                .MuiButtonBase-root.primary,
                .MuiButtonBase-root.primary:hover,
                .MuiButtonBase-root.secondary,
                .MuiButtonBase-root.secondary:hover {
                    margin: unset;
                    padding: unset;
                    min-width: unset;
                }

                &--disabled {
                    opacity: $disabled-opacity;
                    cursor: default;
                    pointer-events: none;
                }
            }
        }

        .form-field__top {
            justify-content: flex-start;

            label {
                color: $color-secondary;
                margin-bottom: $size-sm;
            }
        }
    }

    &.signed-modal {
        .modal-confirm__wrapper {
            background-color: $color-primary;
            border-radius: 0;
            color: white;

            &__header {
                border-bottom: 0;

                &__close-btn {
                    svg {
                        stroke: $color-white;
                    }
                }
            }

            &__content {
                padding-top: 0;
            }

            &:focus {
                outline: none;
            }
        }
    }
}
