/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.isolate-flow-layout {
    display: grid;
    grid-template-rows: auto auto 1fr;
    grid-template-areas:
        "header"
        "title"
        "pagearea";
    height: 100dvh;
    width: 100%;

    &__header {
        grid-area: header;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: $esignatures-layout-padding;
        border-bottom: $base-border;

        &__logo-wrap {
            width: 50%;
            height: $svg-height-lg;
            margin-right: $size-xs;

            svg {
                width: auto;
                height: $svg-height-lg;
            }

            .logo-desktop {
                display: none;
            }
        }

        h1 {
            display: none;
            text-align: center;
        }

        &__user-wrap {
            display: flex;
            align-items: center;
            width: 50%;
            justify-content: flex-end;

            &__name {
                text-align: right;
            }

            .MuiAvatar-root {
                margin-left: $size-md;
                background-color: $color-grey-neutral-100;
                width: $svg-height-xxl;
                height: $svg-height-xxl;

                svg {
                    stroke: $color-secondary;
                }
            }
        }
    }

    > h1 {
        grid-area: title;
        padding: $size-lg;
    }

    &__content {
        grid-area: pagearea;
        overflow: auto;
        display: grid;
        grid-template-rows: 1fr auto;
        grid-template-areas:
            "content"
            "footer";

        .MuiStepper-horizontal {
            grid-area: stepper;
            padding: $esignatures-layout-padding;
        }

        .step-content {
            grid-area: content;
            height: 100%;
            width: 100%;
            overflow: auto;
            padding: $size-sm $size-md $size-xxxl;
        }

        h2 {
            margin-top: $size-xxl;
        }
    }

    .footer {
        grid-area: footer;
        border-top: $base-border;
        box-shadow: $white-shadow;
        padding: $esignatures-layout-padding;
        background-color: $color-white;
        z-index: 1;

        &__btns-wrap {
            button,
            button:hover,
            button:focus {
                margin: $size-xs 0;
                width: 100%;
            }
        }
    }
}

@media only screen and (min-width: $breakpoint-md) {
    .isolate-flow-layout {
        padding: $isolate-flow-page-padding-md;
        background: $gradient-purple-to-white;

        &__header {
            border: none;
            max-width: $breakpoint-xl;
            margin: 0 auto;
            width: 100%;
            padding: 0;

            h1 {
                display: block;
                width: 33%;
            }

            &__logo-wrap {
                width: 33%;

                .logo-mobile {
                    display: none;
                }
                .logo-desktop {
                    display: block;
                    height: $svg-height-xl;
                }
            }

            &__user-wrap {
                width: 33%;

                .MuiAvatar-root {
                    background-color: $color-white;

                    svg {
                        stroke: $color-secondary;
                    }
                }
            }
        }
        > h1 {
            display: none;
        }

        &__content {
            border: $base-border;
            border-radius: $card-border-radius;
            box-shadow: $isolate-flow-card-shadow;
            background-color: $color-white;
            max-width: $breakpoint-xl;
            margin: $size-sm auto 0;
            width: 100%;

            .MuiStepper-horizontal {
                margin: $size-lg 0;
            }
            .footer {
                &__btns-wrap {
                    display: flex;
                    justify-content: space-between;
                    flex-direction: row-reverse;

                    button,
                    button:hover,
                    button:focus {
                        width: auto;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: $breakpoint-xl) {
    .isolate-flow-layout {
        padding: $isolate-flow-page-padding;

        &__header {
            padding: $size-lg 0;
        }

        &__content {
            margin: $size-lg auto 0;

            .MuiStepper-horizontal {
                margin: $size-xxxl 0;
            }
        }
    }
}
