/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.esignature-error {
    padding: 0;
    border: none;

    &__container {
        background-color: $color-grey-neutral-900;
        color: $color-white;
        position: relative;
        padding: $size-xl;
        max-width: $error-modal-max-width;
        display: grid;
        gap: $spacing-sm;

        h2 {
            text-align: center;
            color: $color-white;
            font-size: $font-xl;
        }

        ul {
            padding-left: $size-lg;
        }

        p {
            text-align: center;
        }
        
        p, li {
            color: $color-red-600;
        }
    }

    &__icons {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: $spacing-lg;

        img {
            opacity: 0.5;
        }

        svg {
            fill: $color-red-600;
        }
    }

    &::backdrop {
        background-color: $color-white;
        opacity: 0.9;
    }
}