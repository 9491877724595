/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

@media only screen and (min-width: $breakpoint-md) {
    .review-plan-screen{
        .step-content__screen {
            margin: 0 $size-xl;
        }
    }
}