/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.banner {
    padding: $size-md;
    position: absolute;
    top: 0;
    width: 100%;
    box-shadow: $banner-shadow;
    z-index: 1;
    transition: height 0.2s ease;

    &--info {
        background-color: $color-yellow-todo-200;
    }

    &--hidden {
        display: none;
    }

    &__header {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &__icon {
            height: $svg-height-lg;
            width: auto;
            stroke: $color-secondary;
            margin-right: $size-sm;
        }

        &__content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            &__title {
                font-size: $font-xl;
                margin: $size-xs 0;
            }

            &__close-btn {
                align-items: center;
                justify-content: center;
                cursor: pointer;
                width: $svg-height-xxl;
                height: $svg-height-xxl;
                background-color: transparent;
                border: none;

                svg {
                    stroke: $color-secondary;
                    cursor: pointer;
                }
            }
        }
    }
}
