/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.qes-signature-documents {
    white-space: break-spaces;

    &.bg-secondary {
        color: $color-white;
    }

    h1 {
        font-size: $font-xxl;
    }

    h2 {
        font-size: $font-xl;
    }

    h3 {
        font-size: $font-lg;
        color: $color-grey-neutral-300;
    }

    .line {
        border-top: $base-border;
        height: 1px;
        width: 100%;
        display: block;
    }
}

.qes-signature-banner {
    grid-area: banner; 
    width: 100%;
    text-align: center;
    background-color: $color-cmd;
    padding: $size-md 0;
}

.qes-signature-footer {
    grid-area: footer; 
    padding: $size-md $size-lg;
    background-color: $color-grey-neutral-100;
    color: $color-grey-neutral-900;
    line-height: 1.35;

    .checkbox {
        grid-template-columns: auto auto;
        align-items: start;
        font-size: $font-sm;

        .checkbox__input {
            margin-top: $size-xxxs;
        }
    }
}

.qes-signature-body {
    grid-area: body; 
    height: 100%;
    overflow: auto;
    padding: 0 $size-md;

    
}

.qes-signature-layout {
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr auto; 
    grid-template-areas:
    'banner'
    'body'
    'footer';
    overflow: hidden;
}

.qes-signature-modal {
    align-self: center;

    .custom-btn, .custom-btn:hover, .custom-btn:focus {
        padding-left: 0;
        padding-right: 0;
        margin: $size-sm 0;
    }

    span {
        text-decoration: underline;
        text-align: center;
    }
}

.qes-signature-start-video-validation {
    grid-area: body; 
    height: 100%;
    overflow: auto;
    text-align: center;
}

.qes-signature-otp {

    &__icons {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: $spacing-xs;
        margin-top: $size-xxxl;
    }

    h1, h2, p {
        text-align: center;
    }

    &__title {
        color: $color-primary;
        margin-top: $size-xxxl;
    }

    &__description {
        padding: 0 $size-md;
        
        strong {
            color: $color-primary;
        }
    }

    &__otp-code {
        .smart-code {
            justify-content: center;
        }
        
        h2 {
            color: $color-primary;
            margin: $size-xxxl 0 $size-lg 0;
        }

        &__progress-bar {
            margin-top: $size-xxl;
            padding: 0 $size-md;
    
            p {
                color: $color-primary;
            }
        }
    }

    &__try-again {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        gap: $spacing-sm;
        margin: $size-lg 0;
        width: 100%;
        padding: 0 $size-sm;

        p {
            font-size: $font-xl;
            margin: 0;
            color: $color-primary;
        }

        span {
            font-size: $font-md;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    &__info-card {
        padding: $size-md;
        background-color: $color-grey-neutral-100;
        text-align: left;

        div {
            display: inline-flex;
            justify-content: flex-start;
            align-items: center;
            gap: $spacing-sm;
        }

        h2, h3 {
            color: $color-secondary;
        }

        h3 {
            font-size: $font-md;
        }
    }
}

.eid-overlay *, .eid-ui-canvas * {
    font-family: $font-primary;
    color: $color-secondary;
}

.eid-notification-welcome .eid-notification-centered-content {
    max-width: unset;
    padding: 0 $size-md;
}

.eid-notification-requirements, .eid-idtype-selector {
    margin: 0;
    width: unset;
    padding: 0 $size-lg;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.eid-svg-container {
    svg > * {
        stroke: $color-primary;
    }
}

.eid-h1 {
    color: $color-secondary;
}

.eid-h3 {
    font-size: $font-xxl;
    color: $color-secondary;
}

.eid-h5 {
    font-size: $font-xl;
    color: $color-grey-neutral-300;
}
.eid-a,.eid-a-2 {
    color: $color-primary;
}
.eid-a:hover, .eid-a-2:hover {
    color: $color-brand-600;
}
.eid-hologram-animation-container {
    display: none;
}

.eid-idtype-selector .eid-p {
    font-size: $font-lg;
    color: $color-primary;
    margin: $size-md 0 $size-xs 0;
}

.eid-idtype-selector .eid-description {
    margin: $size-xxxl 0;
}

.eid-choices .choices {
    margin-bottom: $size-lg;
    border: $base-border;
    border-radius: $input-border-radius;
    padding: $size-xs 0;
}

.eid-button {
    background-color: $color-primary;
    border: none;
    color: $color-white;
}
.eid-button:active, .eid-button:hover {
    background-color: $color-brand-600;
}

.eid-choices .choices__inner {
    background: none;
    border: none;
    box-shadow: none;
}

.eid-choices .choices__list--dropdown .choices__item {
    margin: 0;
    display: inline-flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;

    span {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        line-height: unset;
        white-space: nowrap;
    }
}

.eid-notification-centered-content {
    height: unset;
}

.eid-notification .eid-requirements {
    width: unset;
}

.eid-notification-requirements .checkbox-biometric-consent {
    flex-shrink: 0;
}

.eid-notification-requirements .eid-checkbox-biometric-consent-box {
    display: grid;
    grid-template-columns: 1fr auto;
    white-space: initial;
}

.eid-notification-requirements .eid-requirements.eid-call-to-action2 {
    color: $color-primary;
    text-decoration: underline;
}

.eid-notification-warning-error .eid-footer {
    display: none;
}
.iti__flag {
    position: relative;

    img {
        position: absolute;
        top: -2px;
        left: 0;
        bottom: 0;
        right: 0;
    }
}
.eid-telephone-code .eid-textbox {
    position: absolute;
    left: 0;
    border: $base-border;
    border-radius: $input-border-radius;
    padding: $size-xs 0;
    width: 100%;
}


.eid-telephone-code .eid-textbox, .eid-telephone-code .iti {
    width: 100%;
    position: absolute;
    left: 20;
    right: 0;
}
.eid-telephone-code .iti {
    margin: 0 24px;
    width: calc(100% - 48px);
}
.iti__country-list {
    top: 5.5em;
}

.iti--allow-dropdown .iti__flag-container, .iti--separate-dial-code .iti__flag-container {
    height: 2.75em;
}

.eid-loading div {
    border: 5px solid $color-primary;
}

.eid-notification-warning-error .eid-button {
    display: block;
}

.eid-notification-warning-error .eid-equal-container:first-child {
    display: none;
}

@media only screen and (min-width: $breakpoint-md) {
    .eid-notification-warning-error .eid-equal-container:first-child {
        display: flex;
    }
}
