
/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.checkbox-group {
    margin-bottom: $size-md;
    height: $max-height-filter-checkbox;
    overflow: auto;

    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        .MuiButtonBase-root.basic-btn {
            color: $color-primary;
            text-decoration: underline;
            padding: $size-sm 0;

            &:hover,
            &:focus {
                background-color: transparent;
            }
        }
    }
    .form-field__container__input-wrapper {
        margin-bottom: $size-sm;
    }

    .MuiFormGroup-root {
        .checkbox-group__item {
            input[type=checkbox] {
                display:none;
            }
            &.disabled {
                .MuiFormControlLabel-root {
                    .MuiTypography-root {
                        svg {
                            stroke: $color-grey-neutral-200;
                        }
                    }
                }

                &:first-child {
                    .MuiTypography-root {
                        svg {
                            stroke: none;

                            path {
                                fill: $color-grey-neutral-200;
                            }
                        }
                    }
                }
            }
        }
    }

    .show-more-btn {
        text-decoration: underline;
    }

    .disable-warning {
        font-size: $font-sm;
        margin-top: $size-sm;
        display: block;
    }
}
