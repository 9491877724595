/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.error-display-element {
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 50%;
    height: 100%;

    z-index: 3;

    p {
        margin: 0;
        text-align: center;
    }

    .custom-btn, .custom-btn:hover {
        margin: $size-lg 0 0 0;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        padding: $spacing-lg;
        border: $error-border;
        border-radius: 1em;

        height: fit-content;

        color: $color-white;
        background-color: rgba($color-white, .3);
    }
}