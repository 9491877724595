/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.new-credit-card-screen {
    h2 {
        margin-bottom: $size-md;
    }
    .step-content__screen {
        width: 100%;
        display: grid;
        grid-template-columns: auto 40%;
        grid-gap: $spacing-md;
    }
}

@media only screen and (max-width: $breakpoint-md) {
    .new-credit-card-screen {
        .step-content__screen {
            display: block;
        }

        h2 {
            margin: $size-sm 0 $size-md;
        }

        &__container {
            &__info {
                width: 100%;
            }
        }
    }
}

@media only screen and (min-width: $breakpoint-md) {
    .new-credit-card-screen {
        .step-content__screen {
            margin: 0 $size-xl;
            width: calc(100% - $size-xxxl);
        }
    }
}
