/**
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

 .smart-code {
    display: flex;
    gap: 0.8rem;
    height: 40px;
    flex-wrap: wrap;

    input {
        height: 100%;
        padding: $size-xs;
        border: $primary-border;
        box-shadow: $smart-code-shadow;
        border-radius: 0;
        font-size: $font-xxl;
        text-align: center;
        
        &:focus-visible {
            outline: none;
        }
    }

    &--error {
        input {
            border: 1px solid $color-red-300;
        }
    }
 }

 @media only screen and (max-width: $breakpoint-xs) {

    .smart-code {
        gap: 0.6rem;
    
        input {
            font-size: $font-lg;
        }
    }
    
 }