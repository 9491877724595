/*
 *
 * @Copyright 2023 UNLOCK DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

@font-face {
    font-family: 'Aeonik Fono';
    src: url('../../fonts/AeonikFono-Regular.woff2') format('woff2'),
        url('../../fonts/AeonikFono-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}