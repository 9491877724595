/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.quota-limit-exceeded-message {
    padding: $default-page-padding;
    max-width: $breakpoint-md;
    margin: 0 auto;

    &__header {
        button {
            padding-left: 0;
            svg {
                // TODO refactor this variant into the main button component
                stroke: $color-primary;
                margin-right: $size-sm;
            }
        }
        h1 {
            font-size: $font-lg;
            margin-bottom: $size-xl;
        }
    }

    &__description {
        margin-bottom: $size-xl;
    }
   
    &__billing {
        border: $base-border;
        border-radius: $input-border-radius;
        font-size: $font-sm;
        padding: $size-md;
        margin-top: 0;

        li {
            display: flex;
            justify-content: space-between;
            padding: $size-md 0;
        }
        &__header {
            padding-bottom: 0;
            font-size: $font-md;
        }
        &__total {
            border-top: $base-border-2;
            font-size: $font-md;
        }
    }

    .alert {
        margin: $size-lg 0 0 0;
        button {
            margin: $size-xxs;
            margin-left:-$size-sm;
        }
    }
    .btn-wrapper {
        text-align: center;
    }
}

@media only screen and (min-width: $breakpoint-lg){
    .quota-limit-exceeded-message {
      border: $base-border;
      border-radius: $basic-btn-border-radius;
      padding: $size-xxl;
      margin: $size-xxl auto;
    } 
}
@media only screen and (min-width: $breakpoint-xl){
    .quota-limit-exceeded-message {
        h1 {
            font-size: $font-xl;
        }
    } 
}
