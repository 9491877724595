/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.aes-context {
    padding: $default-page-padding;
    text-align: center;

    &__header {
        margin-bottom: $spacing-xxxl;
        text-align: left;

        strong {
            color: $color-cmd;
        }
    }

    .smart-code {
        justify-content: center;
        margin-bottom: $size-xxxl;
    }

    &__try-again {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: $size-sm;
        margin-bottom: $size-xxl;

        button {
            color: $color-cmd;
            background: transparent;
            border: transparent;
            text-decoration: underline;
        }
    }

    &__change-method {
        color: $color-cmd;
        text-align: center;
        background: transparent;
        border: transparent;
        text-decoration: underline;
    }

    &__error {
        text-align: center;
        color: $color-red-600;
    }
}

.aes-signature-banner {
    width: 100%;
    text-align: center;
    background-color: $color-cmd;
    padding: $size-md 0;
}

.aes-heading {
    color: $color-black;
}

.aes-signing-method {
    width: 100%;
    border: none;
    padding: $size-lg;
    background-color: $color-grey-neutral-50;
    display: flex;
    margin-bottom: $size-lg;
    justify-content: space-between;
    align-items: center;

    svg {
        height: $svg-height-xl;
        width: $svg-height-xl;
        fill: $color-grey-neutral-700;
    }

    div {
        width: 100%;
        margin-left: $size-lg;

        h4,
        p {
            margin: $size-xxs 0;
            text-align: start;
        }

        h4 {
            font-size: $font-xl;
            color: $color-grey-neutral-950;
        }

        p {
            font-size: $font-lg;
            color: $color-grey-neutral-700;
        }
    }
}
