/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.signing-layout {
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
        "header header"
        "pagearea pagearea"
        "footer footer";
    height: 100%;
    width: 100%;

    &__header {
        grid-area: header;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 $size-lg;
        border-bottom: $base-border;

        &__btn-back.MuiButtonBase-root {
            display: flex;
            align-items: center;
            width: $back-btn-size;
            height: $back-btn-size;
            min-width: auto;
            border-radius: $border-circle;

            svg {
                stroke: $color-secondary;
            }
        }

        &__action-btn-wrap {
            &.small-btn {
                button,
                .MuiButtonBase-root.custom-btn {
                    margin: $size-sm 0;
                    padding: $small-brand-btn-padding;

                    &.stroked-icon {
                        svg {
                            stroke: inherit;
                        }
                    }
                }
            }
        }
    }

    &__children {
        grid-area: pagearea;
        overflow: auto;
    }
}

@media only screen and (min-width: 1025px) {
    .signing-layout {
        &__header {
            padding: $default-page-padding;

            &__action-btn-wrap {
                &.small-btn {
                    button,
                    .MuiButtonBase-root.custom-btn {
                        margin: 0;
                        padding: $brand-btn-padding;
                    }
                }
            }
        }
    }
}
