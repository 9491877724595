/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.contracts-configurator-screen {
    width: 100%;
    height: 100%;

    &__header {
        margin-top: $size-md;
        padding: 0 $size-lg;
        display: flex;
        justify-content: space-between;
        align-items: center;

        a {
            text-decoration: none;
        }
        .large-add-btn {
            display: none;
        }
    }

    &__list {
        height: 100%;
        
        &__item {
            margin-bottom: $size-md;
            width: 100%;
            list-style: none;

            &__inner {
                padding: $size-md;
                display: flex;
                justify-content: space-between;
                align-items: center;

                &__title {
                    margin: 0;
                    margin-left: $size-sm;
                }
            }
        }
    }
}

@media only screen and (min-width: $breakpoint-md) {
    .contracts-configurator-screen {
        &__header {
            .large-add-btn {
                display: block;
            }
        }
    }
    .add-btn-wrap {
        display: none;
    }
}