/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.esignature-commitments {
    padding: 0;
    list-style-type: none;

    &__item {
        margin-bottom: 1rem;
        &__header-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__select-container {
        display: inline-flex;
        align-items: center;
        gap: 1rem;
    }

    &__description {
        opacity: 0.5;
        margin: 0;
    }
    
    &__oid {
        opacity: 0.5;
    }

    &__empty {
        text-align: center;
    }
}