/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.plans-screen {
    padding: $size-lg;
    width: 100%;

    &__list {
        .MuiBox-root .MuiTabPanel-root, .MuiGrid-root {
            padding: 0;
        }

        &__individual-plans, &__team-plans {
            margin-top: $size-lg;
        }
    }
    &__footer {
        margin-top: $size-xl;

        p {
            margin: $size-xs 0;
            font-size: $font-sm;
        }
    }
}