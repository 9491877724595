/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.change-organization-screen {
    padding: $size-xl;
    width: 100%;

    &__list {
        .infinite-scroll-wrapper {
            max-height: calc(100vh - 165px);
            overflow-y: auto;
        }

        .radio-select__item {
            .MuiButtonBase-root {
                display: none;
            }

            .MuiAvatar-root {
                margin-right: $size-md;
            }
        }
    }

    footer {
        display: flex;
        justify-content: flex-end;
    }
}

@media only screen and (min-width: $breakpoint-lg) {
    .change-organization-screen {
        min-width: $breakpoint-md;
        width: auto;
    }
}
