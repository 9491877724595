/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

 .phone-input {
    box-sizing: content-box;
 


    &__container {
        >div{
            display: flex;
            gap: $size-sm;
            position: relative;
            margin-bottom: $size-xxxs;
        }
    }

    &__country-wrapper {
        position: relative;
        display: inline-block;
    }

    // Dropdown trigger button
    &__selector {
        font-size: inherit;
        font-family: $font-primary;
        border: $base-border;
        border-radius: $input-border-radius;
        outline: none;
        box-shadow: none;
        background-color: $color-white;
        min-width: 100px;
        height: 100%;
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        padding: $input-padding;
        color: $color-secondary;
        
        &:active {
            background-color: rgba($color-grey-neutral-100, 0.3);
        }

        // Style the input as it was a <span>
        input {
            font-size: inherit;
            max-width: 3.5rem;
            appearance: unset;
            padding: 0;
            border: 0;
            text-align: right;
            outline: none;
            font-family: inherit;
        }

    }
    
    // Country dropdown list
    &__list-wrapper {
        position: absolute;
        box-shadow: $input-box-shadow;
        width: 300px;
        margin-top: $size-xs;
        background-color: $color-white;
        z-index: 2;
        font-family: $font-primary;
        color: $color-secondary;
        border: $base-border;
        border-radius: $modal-border-radius;

        // search bar
        input {
            padding: $size-sm $size-md;
            width: 100%;
            height: 100%;
            border: none;
            outline: none;
            font-weight: 400;
            background-color: $color-white;
            font-family: $font-primary;
            font-size: inherit;
        }

        hr {
            border-style: none;
            border-top: $hr-border;
        }

        div {
            max-height: 20vh;
            overflow-y: scroll;
            margin: $size-xs;
            padding: 0;
        }

        button {
            display: grid;
            grid-template-columns: 1rem auto 5rem;
            gap: 0.5rem;
            width: 100%;
            border: none;
            background-color: $color-white;
            font-family: $font-primary;
            font-size: inherit;

            &:hover {
                background-color: $color-brand-200;
                color: $color-white;
            }
            >span {
                font-family: inherit;
                color: $color-secondary;
            }

            .country {
                text-align: left;
            }

            .dial {
                text-align: left;
            }
        }

    }

    // Phone number input field
    &__phone-number {
        border: $base-border;
        border-radius: $input-border-radius;
        box-shadow: none;
        width: 100%;
        height: 100%;
        font-size: inherit;
        text-overflow: ellipsis;
        letter-spacing: 2px;
        font-family: inherit;
        color: $color-secondary;
        padding: $size-md;

        &:focus {
            outline: none;
        }
    }
 }