/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.support-ticket-submitted-screen {
    width: 100%;
    height: 100%;
    padding: $size-lg;
}