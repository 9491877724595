/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.progress-bar {
    width: 100%;
    height: 5px;
    display: flex;
    flex-wrap: nowrap;
    
    :nth-child(n) {
        height: 100%;
        transition: width 1s linear;
    }
    
    :last-child {
        opacity: 0.3;
        transition: width 1s linear;
    }
    
    .xs {
        height: 5px;
    }

    .primary {
        background-color: $color-primary;
    }

    .secondary {
        background-color: $color-secondary;
    }

}