/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.top-notification-alert {
    background-color: $color-yellow-todo-300;
    padding: $size-sm;
    font-size: $font-sm;
    width: 100%;
}