/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.invitation-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    h1 {
        font-size: 20px;
        margin-bottom: 15px;
    }

    &__instruction {
        text-align: center;
        font-size: 10px;
        p {
            margin: 0;
            line-height: 1.5;
        }
    }
}
