/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.nav-bar-wrap {
    position: relative;
    background-color: $color-white;

    .nav-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: $navbar-padding-mobile;
        position: relative;
        z-index: 101;
        background-color: $color-white;
        border-bottom: $base-border;

        &__inner {
            min-width: 25%;
            display: flex;
            align-items: center;

            &__logo-wrap {
                width: auto;
                height: $logo-svg-height;

                svg {
                    width: auto;
                    height: 100%;
                }
            }

            h1 {
                margin-left: $size-md;
            }
        }

        .navigation-wrap {
            min-width: 50%;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .my-plan-btn {
                margin: $size-sm;
                padding: $size-sm $size-md;
                min-width: $svg-height-lg;

                .MuiButton-startIcon {
                    margin-right: 0;
                }

                .hide-mobile-text {
                    display: none;
                }
            }
            > ul {
                margin: 0;
                padding: 0;
                display: flex;
                justify-content: flex-end;

                > li {
                    width: $icon-btn-size;
                    height: $icon-btn-size;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    > .MuiButtonBase-root {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                    }

                    svg {
                        stroke: $color-secondary;
                    }

                    &.organization-item {
                        margin-right: 0;
                        margin-left: $size-xs;
                        position: relative;

                        button {
                            border: none;
                            background: none;
                        }

                        .MuiAvatar-root {
                            background-color: $color-grey-neutral-100;
                            width: $svg-height-xxl;
                            height: $svg-height-xxl;

                            svg {
                                stroke: $color-secondary;
                            }
                        }

                        .organization-avatar {
                            width: $svg-height-md;
                            height: $svg-height-md;

                            position: absolute;
                            bottom: 0;
                            right: 0;

                            &.MuiAvatar-root {
                                background-color: $color-grey-neutral-200;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 1025px) {
    .nav-bar-wrap {
        .nav-bar {
            padding: $navbar-padding;

            &__inner {
                &__logo-wrap {
                    display: none;
                }

                h1 {
                    margin: 0;
                }
            }

            .navigation-wrap {
                .my-plan-btn {
                    margin: $size-sm $size-md $size-sm 0;
                    padding: $size-sm $size-md;
                    .MuiButton-startIcon {
                        margin-right: $size-sm;
                    }
                    .hide-mobile-text {
                        display: block;
                    }
                }
                > ul {
                    > li {
                        width: $spacing-xl;
                        height: $spacing-xl;
                    }
                }
            }
        }
    }
}
