/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.menu-bar {
    background-color: $color-secondary;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    $menu-bar: &;

    &__logo-wrap_desktop {
        display: none;
    }
    svg {
        height: $svg-height-xl;
        width: auto;
        stroke: $menu-color;
    }

    &--scrollable {
        height: 100%;
        overflow: auto;
        z-index: 101;

        &:not(.open) {
            #{$menu-bar}__wrap-list {
                display: none;
            }
        }

        > button {
            position: absolute;
            top: 2px;
            width: 65px;
            height: 55px;

            background-color: $color-white;
            border: none;

            svg {
                stroke: $color-secondary;
            }
        }

        h3 {
            color: $color-grey-neutral-400;
            padding-left: $size-lg;
        }

        #{$menu-bar}__wrap-list {
            &__go-to-link {
                text-decoration: none;
                color: $color-white;
                display: inline-flex;
                gap: $size-xxs;
                align-items: center;
                justify-content: center;
                margin-top: $size-lg;
                padding-left: $size-lg;
    
                a {
                    margin-top: $size-xl;
                }
                svg {
                    stroke: $color-white;
                }
            }
            &__list {
                flex-direction: column;
                align-items: flex-start;

                > a {
                    &.disabled {
                        pointer-events: none;
                        cursor: not-allowed;
                        opacity: 0.5;
                    }
                    .menu-bar__list__item {
                        flex-direction: row;
                        gap: $size-lg;
                        border-left: $menu-mobile-default-border;
                        border-top: none;
                        &__title {
                            font-size: $font-md;
                        }
                    }
                }
            }
            &__account-center {
                background-color: $color-grey-neutral-950;
                padding: $size-xxs $size-md $size-xs;
                border-radius: $basic-btn-border-radius;
                margin: $size-lg $size-md $size-xxl;
                h3 {
                    color: $color-white;
                    padding: 0;
                    border: 0;
                    margin-top: $size-md;
                }
                p {
                    font-size: $font-sm;
                    color: $color-grey-neutral-200;
                }
                ul {
                    padding: 0;
                    >a {
                        text-decoration: none;
                        &.disabled {
                            pointer-events: none;
                        }
                        li {
                            display: flex;
                            align-items: center;
                            svg {
                                margin-right: $size-sm;
                                width: $svg-height-md;
                                height: $svg-height-md;
                            }
                            .menu-bar__list__item__title {
                                color: $menu-color;
                                font-size: $font-sm;
                            }
                        }
                    }
                }
                .manage-accounts-link {
                    display: block;
                    margin: $size-xs 0 0;
                    text-decoration: none;
                    font-size: $font-sm;
                    color: $color-brand-300;
                }
                .coming-soon {
                    display: inline-block;
                    margin: $size-xxs 0 $size-md;
                    text-decoration: none;
                    font-size: $font-xs;
                    color: $color-grey-neutral-300;
                }
            }
        }
    }

    &__wrap-list {
        &__list {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0;
            padding: 0;

            > a {
                display: inline-block;
                text-decoration: none;

                .menu-bar__list__item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    border-top: $menu-mobile-default-border;
                    padding: $menu-bar-mobile-padding;

                    &__icon-wrap {
                        width: $svg-height-xl;
                        height: $svg-height-xl;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    &__title {
                        font-size: $font-xs;
                        color: $menu-color;
                        display: inline-block;
                        margin-top: $size-xxs;
                        line-height: 1.6;
                    }
                }

                &.active {
                    .menu-bar__list__item {
                        border-color: $color-primary;

                        &__icon-wrap {
                            svg {
                                stroke: $active-menu-color;
                            }
                        }

                        &__title {
                            color: $active-menu-color;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 340px) {
    .menu-bar--scrollable {
        .menu-bar__wrap-list {
            &__list {
                > a {
                    .menu-bar__list__item {
                        &:first-child,
                        &:last-child {
                            padding: $menu-bar-mobile-extra-side-padding;
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 1025px) {
    .menu-bar {
        display: grid;
        grid-template-rows: auto 1fr auto;
        grid-template-areas:
            "logo"
            "menu"
            "bottom";
        height: 100dvh;
        width: $large-menu-min-width;
        $menu-bar: &;

        &--scrollable {
            &:not(.open) {
                #{$menu-bar}__wrap-list {
                    display: unset;
                }
            }

            > button {
                display: none;
            }
        }
        &__logo-wrap_desktop {
            grid-area: logo;
            display: flex;
            flex-direction: column;
            margin: $size-xl 0 $size-sm;
            justify-content: center;
            align-items: flex-start;
    
            >svg{
                width: 100%;
            }
        }


        &__wrap-list {
            grid-area: menu;
            display: flex;
            align-items: center;
            max-height: 100%;
            $wrap-list: &;

            &--scrollable-list {
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                overflow: auto;

                h3 {
                    color: $color-grey-neutral-400;
                    border-left: $menu-desktop-default-border;
                }

                #{$wrap-list}__list {
                    height: unset;
                    overflow: unset;
                }
            }

            &__list {
                display: flex;
                flex-direction: column;
                width: 100%;
                align-items: flex-start;
                justify-content: flex-start;
                max-height: 100%;
                overflow: auto;

                > a {
                    .menu-bar__list__item {
                        flex-direction: row;
                        border: none;
                        border-left: $menu-desktop-default-border;

                        &,
                        &:first-child,
                        &_last-child {
                            padding: $menu-bar-desktop-item-padding;
                        }

                        &__icon-wrap {
                            margin-right: $size-lg;
                        }

                        &__title {
                            margin: 0;
                            font-size: 1rem;
                        }
                    }
                    &.active {
                        .menu-bar__list__item {
                            border-color: $color-primary;
                        }
                    }
                }
            }
        }

        &__bottom {
            grid-area: bottom;
            height: $spacing-xl;
        }
    }
}
