/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.new-support-ticket-screen {
    width: 100%;
    height: 100%;
    padding: $size-lg;

    form {
        padding-top: $size-lg;
    }

    &__actions {
        width: 100%;
        display: flex;
        justify-content: space-between;

    }
}