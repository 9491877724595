/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.review-document-option {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: $spacing-lg;

    h3 {
        color: $color-primary;
    }

    p {
        margin: 0;
        text-align: left;
    }

    &__review-options {
        width: 100%;

        .radio-select {
            .MuiFormGroup-root {
                .radio-select__item {
                    &:first-child {
                        .MuiTypography-root {
                            svg {
                                path {
                                    fill: none;
                                }
                            }
                        }
                    }
                }
            }

            &__item {
                svg {
                    stroke: $color-grey-neutral-100;
                }
            }
        }
    }

    &__buttons {
        display: flex;
        justify-content: center;
        gap: $spacing-sm;
        padding: 0 $size-md;

        button {
            height: $back-btn-size;
        }
    }
}

.review-document-confirmation-modal {
    h3 {
        color: $color-primary;
    }

    &__buttons {
        display: flex;
        justify-content: center;
        gap: $spacing-sm;
        padding: 0 $size-md;

        button {
            height: $back-btn-size;
        }
    }
}

.notes-drawer {
    .drawer__wrapper__content {
        white-space: break-spaces;
    }
}
