/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.complete-checkout-screen {
    .step-content__screen {
        width: 100%;
        margin: 0 auto;
    }

    h2 {
        margin-bottom: $size-md;
    }

    &__container {
        margin-top: $size-xxl;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        
        h2 {
            margin-top: 0;
            margin-bottom: $size-xxl;
        }
        
        &__links {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: $size-md;
        }
    }
}
