/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.user-profile-side-bar {
    position: absolute;
    background-color: $color-white;
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    align-items: flex-start;
    left: 50%;
    width: 90vw;
    max-height: calc(100vh - 125px);
    overflow: auto;
    border-radius: $image-border-radius;
    box-shadow: $user-profile-side-bar-shadow;
    transform: translate(-50%, -115%);
    transition: transform 500ms cubic-bezier(0.785, 0.01, 0.545, 0.995);
    z-index: 100;

    > div {
        padding: $size-lg;
        width: 100%;

        &:not(:last-child) {
            border-bottom: $hr-border;
        }
    }

    &.visible {
        transform: translate(-50%, 10px);
    }

    .user-info {
        display: inline-block;

        > :first-child {
            margin-bottom: $size-xxxl;
        }
    }

    .wallet-info {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
            color: $color-grey-neutral-500;
        }

        span.amount {
            font-size: $font-xl;
        }

        p,
        span.amount {
            margin: 0;
        }
        &__buttons {
            padding-left: $size-sm;
            display: flex;
            gap: $size-sm;
        }
    }

    .MuiAvatar-root {
        background-color: $color-grey-neutral-100;
        width: $svg-height-xxxl;
        height: $svg-height-xxxl;

        svg {
            stroke: $color-secondary;
        }
    }

    .organization-info {
        width: 100%;

        > button {
            margin-bottom: $size-md;
            a {
                display: block;
            }
        }
    }

    .icon-title-description {
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;

        &__icons {
            position: relative;

            .organization-avatar {
                width: $svg-height-lg;
                height: $svg-height-lg;

                position: absolute;
                bottom: -6px;
                right: -6px;

                &.MuiAvatar-root {
                    background-color: $color-grey-neutral-200;
                }
            }
        }

        &__info {
            margin-left: $size-md;
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            h2,
            p {
                margin: 0;
            }

            p {
                color: $color-grey-neutral-500;
            }
        }

        &.organization-card {
            border: $hr-border;
            border-radius: $basic-btn-border-radius;
            width: 100%;
            padding: $size-sm;
            .icon-title-description__icons .MuiAvatar-root {
                background-color: $color-grey-neutral-200;
            }
        }
    }

    .useful-links {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: $size-sm;

        a {
            font-size: $font-md;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: $size-sm;
            text-decoration: none;
            color: $color-secondary;
            stroke: $color-secondary;
        }
    }

    footer {
        width: 100%;
        padding: $size-lg;

        button {
            background: none;
            border: none;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 0;
            font-size: $font-md;
            font-family: $font-primary;
            color: $color-secondary;

            svg {
                margin-right: $size-sm;
                fill: $color-grey-neutral-900;
            }
        }
    }
}

@media only screen and (min-width: $breakpoint-md) {
    .user-profile-side-bar {
        right: $size-sm;
        left: unset;
        width: $breakpoint-sm;
        transform: translate(0, -115%);

        &.visible {
            transform: translate(0, 10px);
        }
        .wallet-info {
            span.amount {
                font-size: $font-xxl;
            }
        }
    }
}
