/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.invite-item {
    width: 100%;
    padding: $size-lg;
    margin-bottom: $size-lg;
    position: relative;
    background-color: $color-white;
    
    &__wrap {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: transparent;
        border: none;

        &__profile {
            display: flex;
            align-items: center;
            flex-grow: 1; 
            flex-shrink: 0; 
            max-width: calc(100% - 50px);
        

            &__avatar {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $color-primary;
                border-radius: 50%;
                width: $svg-height-xxl;
                height: $svg-height-xxl;
                overflow: hidden;
                margin-right: $size-md;
                opacity: 0.5;
                flex-shrink: 0;

                img {
                    width: $svg-height-xxl;
                }
            }

            &__info {
                overflow: hidden; 
            
                &__email {
                    &__info__email {
                        display: block;
                        max-width: 100%;
                      }
                    p {
                        font-size: $font-md;
                        margin: 0;
                        margin-top: $size-xs;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      
                    }
                }
            }
        }
        &__actions {
            flex-shrink: 0;
        }
    }
}
