.custom-button-group {
  display: flex;

  .MuiButton-root {
    background-color: $color-brand-500;  
    color: $color-white;  
    padding: $size-xs;

    svg {
      stroke: $color-white;
    }

    &:hover {
      background-color: $color-brand-600; 
    }
  } 

  .MuiButtonGroup-grouped {
    border-radius: $size-xs;  
  }

  .MuiButtonGroup-grouped:not(:last-child) {
    border-right: $base-border-3;  
  }

  &.grey {
    .MuiButton-root {
      background-color: $color-grey-neutral-100;
      color: $color-secondary;
      border: $hr-border;

      svg {
        stroke: $color-secondary;
      }

      &:hover {
        background-color: $color-grey-neutral-200;
      }
    }
  }
  &.blue {
    .MuiButton-root {
      background-color: $color-brand-500;  
      color: $color-white;  

      svg {
        stroke: $color-white;
      }

      &:hover {
        background-color: $color-brand-600; 
      }
    }
  }
}
