/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.usage-chart {
    margin: $size-sm 0 $size-lg;
    width: 100%;
    border-radius: $input-border-radius;
    background-color: $color-grey-neutral-100;
    height: $usage-chart-height;
    display: flex;
    overflow: hidden;

    &__available {
        background-color: $color-primary;
    }

    &__captive {
        background-color: $color-brand-200;
    }
    &.red {
        .usage-chart__available {
            background-color: $color-error;
        }

        .usage-chart__captive {
            background-color: $color-red-100;
        }
    }
}
