
/**
 *
 * @Copyright 2024 UNLOCK DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.home-screen {
    padding: 0 $size-lg;
    position: relative;
    width: 100%;

    &::before {
        content: "";
        background-color: $color-brand-100;
        height: $background-element-height;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index:-1;
    }
    &__quick-actions {
        padding-top: $size-xl;

        a {
            display: inline-block;
            text-decoration: none;
            color: $color-secondary;
            height: 100%;
            width: 100%;
        }
        &__card {
            border-radius: $basic-btn-border-radius;
            padding: $size-xl $size-lg;
            text-align: center;
            font-size: $font-md;
            height: 100%;
            transition: $input-transition;
            
            &__icon-wrap {
                stroke: $color-secondary;
                margin-bottom: $size-sm;

                svg {
                    height: $svg-height-xl;
                    width: $svg-height-xl;
                }
            }
            &:hover {
                box-shadow: $card-shadow;
            }
        }
    }
}

