/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.roles-screen {
    width: 100%;
    padding: $size-lg;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        a {
            text-decoration: none;
        }
        .large-add-btn {
            display: none;
        }
    }

    ul {
        height: 100%;
        padding: 0;

        li {
            margin-bottom: $size-md;
            width: 100%;
            cursor: pointer;

            // Inner alignment
            padding: $size-md $size-xl;
            display: flex;
            justify-content: space-between;
            align-items: center;

            h3 {
                margin: 0;
            }

            p {
                margin: 0;
            }
        }
    }
}

@media only screen and (min-width: $breakpoint-md) {
    .roles-screen {
        &__header {
            .large-add-btn {
                display: block;
            }
        }
    }
}