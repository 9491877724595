/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.create-organization-screen {
    width: 100%;

    h2 {
        margin-bottom: $size-xxl;
    }

    .upload-document-card {
        margin: $size-sm 0;
    }

}
@media only screen and (min-width: $breakpoint-md) {
    .create-organization-screen{
        .step-content__screen {
            margin: 0 $size-xl;
        }
    }
}