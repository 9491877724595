/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */
.nav-link {
    text-decoration: none;
    color: $color-white;

    &:hover {
        text-decoration: underline;
    }
}