/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.workflow-screen--create {
    width: 100%;

    h1 {
        font-size: $font-xl;
        padding: $size-sm 0;
    }

    .horizontal-stepper {
        padding: $size-md 0;
    }

    .step-title {
        font-size: $font-lg;
        margin: $size-xl 0;
    }

    .workflow-screen--create__info-step {
        padding: $default-page-padding;

        .currency-input label {
            margin-bottom: $size-sm;
        }

        .commission-type-and-value-row {
            .form-field__container__input-wrapper {
                margin-bottom: $size-sm;
            }
        }
        .commission-value-wrapper {
            color: $color-grey-neutral-500;
            margin-bottom: $size-lg;
        }

        .description {
            margin-bottom: $size-xs;

            &__max-length {
                display: flex;
                justify-content: flex-end;
                color: $color-grey-neutral-500;
                font-size: $font-sm;
            }
        }

    }

    .workflow-screen--create__property-step {
        padding: $default-page-padding;
    }
    .workflow-screen--create__participants-step {
        padding: $default-page-padding;

        &__add-btn, > .MuiButtonBase-root {
            margin-top: $size-lg;
            width: 100%;
        }
        
    }
}

@media only screen and (min-width: $breakpoint-sm){
    .value-and-id-row {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: $spacing-md;

        .externalId-wrapper {
            order: 1;
        }
    }
}

@media only screen and (min-width: $breakpoint-lg){
    .workflow-screen--create {
        min-width: $breakpoint-md;
        width: auto;
    }
}