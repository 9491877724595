/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

 .workflow-participants-screen {
    padding: $default-page-padding;
    width: 100%;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: -$size-md;
        margin-bottom: $size-md;


        &__back-and-title {
            display:flex;
            align-items: center; 

            button {
                margin-right: $size-sm;
            
                svg {
                    stroke: $color-secondary;
                }
            }
            
            h1 {
                font-size: $font-xl;

                .count {
                    margin-left: $size-xxs;
                }
            }
        }
    }
    .document-card-list {
        >a { 
            text-decoration: none;
        }
    }
}
