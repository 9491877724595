/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.participant-card {
    display: inline-grid;
    grid-template-columns: auto max-content;
    width: 100%;
    padding: $size-md;
    position: relative;

    border: $base-border;
    border-radius: $basic-btn-border-radius;

    // add a pseudo element with border bottom to any card element that is not last element
    &:not(:last-of-type) {
        &::after {
            content: "";
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            border-bottom: $base-border;
        }
    }

    // reset bottom radiuses and border-bottom if card is the 1st in a list
    &:first-of-type:not(:only-child) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        border-bottom: none;
    }

    // reset top radiuses and border-top if card is the last in a list
    &:last-of-type:not(:only-child) {
        border-top-left-radius: 0;
        border-top-right-radius: 0;

        border-top: none;
    }

    // reset radiuses and top/bottom border if card is the middle of a list
    &:not(:first-of-type):not(:last-of-type) {
        border-radius: 0;

        border-bottom: none;
        border-top: none;
    }

    &__info {
        width: 100%;
        display: inline-grid;
        grid-template-columns: max-content auto;
        align-items: center;
        justify-content: start;
        gap: $spacing-lg;

        &__icon-wrap {
            width: $svg-height-xxxl;
            height: $svg-height-xxxl;
            background-color: $color-grey-neutral-100;
            border-radius: $border-circle;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                stroke: $color-primary;
                opacity: 1;
                height: $svg-height-lg;
                width: auto;
            }
        }

        &__details {
            overflow: hidden;

            p {
                margin: 0;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            p:first-of-type {
                margin-bottom: $size-sm;
            }

            &__role {
                font-size: $font-xs;
                line-height: $font-sm;
                color: $color-primary;
                border: $primary-border;
                border-radius: $font-lg;
                padding: $size-xxs $size-xs;
                width: max-content;
            }
        }
    }
    &__actions {
        display: flex;
        justify-items: flex-end;
        align-items: center;
        gap: $size-sm;
        
        .share-component button, .share-component button:hover {
            margin-top: 0;
        }
        
        &__icon-wrap {
            display: flex;
            justify-content: flex-end;

            .MuiButton-root {
                padding: 0;
                min-width: unset;
        
                svg {
                    fill: $color-secondary;
                }
            }
        }
    }
}

.participant-card-list {
    border-radius: $basic-btn-border-radius;
    box-shadow: $workflow-button-shadow;
    padding: 0;
    max-height: 500px;
}

@media only screen and (min-width: $breakpoint-xs) {
    .participant-card {    
        &__info {
            &__details {  
                &__role {
                    font-size: $font-sm;
                }
            }
        }
    }
}
