/**
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

 .cmd-credentials {

    &__container {
        padding: $default-page-padding;

        &__cmd-phone-input {
            margin-bottom: $size-xxxl;
        }

        &__bottom {
            margin-top: $spacing-xxxl;
        }

        label {
            display: inline-block;
            margin-bottom: $size-xs;
        }
    }

    &__header {
        color: $color-primary;
    }

    &__link {
        color: $color-secondary;
    }

    &__actions {
        margin-top: 1rem;
        display: flex;
        justify-content: center;
        gap: 1rem;

    }

    &__cmd-pin-digits {
        max-width: $breakpoint-xs;
        padding-left: $size-xxs;
        padding-right: $size-xxs;
    }
 }