/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.workflow-select-type {
    padding: $size-xxxl $size-lg 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: $size-xxxl;

    h1 {
        font-size: $font-xxl;
        line-height: 1.2;
    }

    section {
        margin-top: $size-xxxl;
        display: flex;
        flex-direction: column;
        gap: $spacing-xs;

        button {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: $base-border;
            background-color: $color-white;
            box-shadow: $workflow-button-shadow;
            color: $color-grey-neutral-900;
            font-size: $font-md;
            line-height: 1.6;
            padding: $size-sm;
            border-radius: $basic-btn-border-radius;

            svg {
                stroke: $color-black;
            }

            &:disabled {
                opacity: 0.5;
                cursor: default;
            }
        }
    }

    .loading-circles {
        margin-top: $size-xxxl;
    }
}