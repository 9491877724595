/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.workflow-missing-documents-screen {
    padding: $default-page-padding;
    height: 100%;
    width: 100%;

    $header-height: 40px;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: -$size-md;
        margin-bottom: $size-md;
        height: $header-height;

        &__back-and-title {
            display: flex;
            align-items: center;

            button {
                margin-right: $size-sm;

                svg {
                    stroke: $color-secondary;
                }
            }

            h1 {
                font-size: $font-xl;

                .count {
                    margin-left: $size-xxs;
                }
            }
        }
    }

    .pdf-viewer-wrap {
        height: calc(100% - $header-height);
    }

   .upload-document-card-list {
        padding: $size-xl $size-md;
        gap: $size-sm;
        > a {
            text-decoration: none;
        }
    }
}
