/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.select-organization-screen {
    margin: auto 0;

   .step-content__screen {
        max-width: $breakpoint-sm;
        margin: 0 auto;
    }

    &__list {
        .radio-select__item {
            .MuiButtonBase-root {
                display: none;
            }
            .MuiAvatar-root {
                margin-right: $size-md;
            }
        }
    }

    .create-organization-btn {
        display: inline-block;
        margin-top: $size-xl;
    }
}

@media only screen and (min-width: $breakpoint-md) {
    .select-organization-screen {
        margin: 0 auto;
    }
}