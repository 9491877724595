/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.organization-screen {

    // Add the right page styles
    div {
        background-color: white;
    }
}
