/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.cmd-otp {
    padding: $default-page-padding;
    overflow-y: scroll;
    height: 100%;

    h3 {
        color: $color-primary;
    }

    &__progress-bar {
        margin-top: 2rem;

        p {
            color: $color-primary;
        }
    }

    &__resend {
        display: flex;
        justify-content: space-between;

        button {
            outline: none;
            background-color: transparent;
            border: none;
            text-decoration: underline;
            color: $color-secondary;
        }
    }

    &__warning {
        background-color: $color-grey-neutral-100;
        padding: 1.5rem;

        a {
            color: $color-primary;
        }
    }

    &__warning-header {
        display: inline-flex;
        align-items: center;
        gap: 1rem;
        text-align: start;

        h3 {
            margin: 0;
        }
    }
}