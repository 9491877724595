/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.date-picker {
    margin-bottom: $size-md;
    
    &__top {
        width: 100%;
        display: inline-flex;

        label {
            color: $color-secondary;
            margin-bottom: $size-sm;
        }
    }

    .bg-gray-filled {
        background-color: $color-grey-neutral-50;
        border-color: $color-grey-neutral-50;
    }

    &__container {
        .MuiButtonBase-root {
            .MuiPickersDay-root {

                &:hover, &:focus {
                    background-color: $color-brand-200;
                }

                &.Mui-selected {
                    background-color: $color-primary;

                    &:hover, &:focus {
                        background-color: $color-brand-600;
                    }
                }

                &.MuiPickersDay-today {
                    border: $primary-border;
                }
            }
        }
        &__quick-buttons {
            button {
                padding: $size-xs $size-xxs;
                
                &:first-child {
                    padding-left: 0;
                }
                
                .text-badge {
                    font-size: $font-sm;
                }
            }
        }
    }
}