/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.infinite-scroll-wrapper {
    height: 100%;
    overflow: auto;
    padding: $size-lg;
    box-sizing: border-box;
}
