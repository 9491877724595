/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.invite-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url('../../images/invite-background-desktop.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 60px 30px;

    &__logo {
        height: 100px;
    }

    &__content {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &__title {
            font-size: $font-xxl;
            line-height: 1;
            color: $color-primary;
            margin: $size-sm 0 $size-lg;
        }

        &__message {
            text-align: center;
            line-height: 1;
            font-size: $font-xl;
            color: $color-secondary;
            margin-bottom: 0;
        }
    }

    @media only screen and (max-width: 767px) {
        background-image: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url('../../images/invite-background-mobile.jpg');
    }
}
