/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.loading-circles {
    display: flex;
    justify-content: center;
    align-items: center;

    div {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: $color-primary;
        margin: 0 10px;
        animation: flash 1s infinite;

        &:nth-child(2){
            animation-delay: 0.3s;
        }
    
        &:nth-child(3){
            animation-delay: 0.6s;
        }
    }

    .xs {
        width: 5px;
        height: 5px;
        margin: 0 5px;
    }

    .s {
        width: 10px;
        height: 10px;
        margin: 0 10px;
    }

    .m {
        width: 14px;
        height: 14px;
        margin: 0 14px;
    }

    .l {
        width: 18px;
        height: 18px;
        margin: 0 18px;
    }

    .xl {
        width: 24px;
        height: 24px;
        margin: 0 24px;
    }

    .primary {
        background-color: $color-primary;
    }

    .secondary {
        background-color: $color-secondary;
    }

    .neutral {
        background-color: $color-white;
    }

    @keyframes flash {
        0%, 20%, 100% {
            opacity: 0.3;
        }
        40%, 60% {
            opacity: 1;
        }
        80% {
            opacity: 0.3;
        }
    }
}

.input-loading-wrapper {
    border: $base-border;
    border-radius: $input-border-radius;
    padding: $loading-input-padding;
    width: 100%;
    height: 100%;
    outline: none;
    background-color: $color-white;
    font-family: $font-primary;
    transition: ease-out 500ms;
    margin-bottom: $size-lg;

    .loading-circles {
        justify-content: flex-start;
    }
}
