/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

 .rectangle-stepper {
    display: flex;
    flex-wrap: wrap;
    padding: 0;

    &__item {
        display: inline-block;
          position: relative;

        &__container {
            display: flex;
            flex-direction: row;
            text-align: center;
            justify-content: center;
            align-items: center;
            padding: 0.59rem $stepper-size;
            gap: 0.5rem;
        }

        &__name {
            margin: 0;
            font-size: 1rem;
        }
    }

    &__icon {
        color: $color-white;
    }

    & .ongoing {
        background-color: $color-primary;
        color: $color-white;

        &::after{
            content: '';
            position: absolute;
            top: 0;
            left: 100%;
            z-index: 10;
            margin-top: 0; /* Half of the triangle height, adjust as needed */
            border-width: $stepper-size; /* Adjust the size of the triangle */
            border-style: solid;
            border-color: transparent transparent transparent $color-primary; /* Color of the triangle */
        }
    }

    & .completed {
        background-color: $color-primary;
        color: $color-white;
        opacity: 0.4;

        &::after{
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 100%;
            z-index: 10;
            margin-top: 0; /* Half of the triangle height, adjust as needed */
            border-width: $stepper-size; /* Adjust the size of the triangle */
            border-style: solid;
            border-color: transparent transparent transparent $color-primary; /* Color of the triangle */
            opacity: 0.4;
        }
    }

    & .todo{
        background-color: $todo-color;
        opacity: 0.4;
        color: $color-secondary;

        &::after{
            content: '';
            position: absolute;
            top: 0%;
            left: 100%;
            z-index: 10;
            margin-top: 0; /* Half of the triangle height, adjust as needed */
            border-width: $stepper-size; /* Adjust the size of the triangle */
            border-style: solid;
            border-color: transparent transparent transparent $todo-color; /* Color of the triangle */
        }
    }
 }