/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.progress-stepper {
    display: flex;
    align-items: space-between;
    gap: 4px;

    &__wrapper {
        width: 100%;
        padding: $size-xxs;
        margin: $size-lg 0;

        h3 {
            text-align: center;
            font-size: $font-sm;
            color: $color-primary;
            margin-bottom: 0;
        }
    }
        
    &__step-item {
        flex-grow: 1; 
        display: inline-block;
        height: 5px;
        border-radius: 2px;
        background-color: $color-brand-100;

        &.completed {
            background-color: $color-primary;
        }
    }

}