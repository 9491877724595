/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.participant-assign-pin-validation-screen {
    padding: $default-page-padding;
    width: 100%;

    &__header {
        margin-bottom: $spacing-xxxl;

        strong {
            color: $color-cmd;
        }
    }

    .smart-code {
        justify-content: center;
        margin-bottom: $size-xxxl;
    }

    &__error {
        text-align: center;
        color: $color-red-600;
    }
}
