/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.workflow-documents-screen {
    padding: $default-page-padding;
    height: 100%;
    width: 100%;
    $header-height: 40px;

    .banner {
        top: calc(1rem + $header-height + $size-md);
        left: 0;
        opacity: 1;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: -$size-md;
        margin-bottom: $size-md;
        height: $header-height;

        &__back-and-title {
            display: flex;
            align-items: center;

            button {
                margin-right: $size-sm;

                svg {
                    stroke: $color-secondary;
                }
            }

            h1 {
                font-size: $font-xl;

                .count {
                    margin-left: $size-xxs;
                }
            }
        }
    }

    .pdf-viewer-wrap {
        height: calc(100% - $header-height);
    }

    .document-card-list {
        padding: $size-xl $size-md;
        > a {
            text-decoration: none;
        }
    }
}

.banner-document-notes {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: $spacing-sm;

    button {
        background: none;
        border: none;
        text-decoration: underline;
    }
}
