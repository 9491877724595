/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.sidebar {
    background-color: $color-secondary;
    position: absolute;
    width: 100vw;
    max-width: 400px;
    height: 100dvh;
    top: 0;
    right: 0;
    z-index: 200;
    padding: $sidebar-padding;
    transform: translateX(100%);
    transition: transform 500ms $cubic-benzier-easing;
    display: flex;
    flex-direction: column;
    box-shadow: $sidebar-shadow;

    &.isOpenSideBar {
        transform: translateX(0);
    }

    &__top-bar {
        display: flex;
        justify-content: space-between;
        margin-bottom: $size-xxl;
        align-items: center;

        &__btn-close.MuiButtonBase-root {
            border-radius: $border-circle;
        }

        &__logo {
            height: $svg-height-xl;
            width: auto;
            
            path {
                fill: $color-white;
            }
        }
    }
    
    .nav-link-list {
        &_item {
            margin-bottom: $size-lg;
        }
    }

    &__links {
        flex-grow: 1;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: $navlinks-padding;
    }

    .MuiDivider-root {
        margin: 0 $size-sm;
        border-color: $color-grey-neutral-700;
    }

    &__language-selector {
        margin-top: $size-md;
        padding: 0 $size-sm;
    }

    &__logout {
        padding: 0 $size-sm;
        color: $color-white;

        >button {
            width: 100%;
        }
    }


    &__sponsors {
        &__text {
            margin-left: $size-sm;
            font-size: $font-xs;
            color: $color-white;
        }

        &__image {
            display: block;
            height: 100%;
            width: auto;
            max-height: 40px;
        }
    }
}