/**
*
* @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
* Development by VOID Software, SA
*
*/

.drawer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $size-md;
    color: $color-secondary;

    &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $color-white;
        position: relative;

        &__header {
            border-bottom: $base-border;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: $size-md $size-lg;

            h3 {
                text-align: center;
                font-size: $font-xl;
                margin: 0;
                color: $color-secondary;
            }

            &__close-btn {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                width: $svg-height-xxl;
                height: $svg-height-xxl;
                background-color: transparent;
                border: none;

                svg {
                    stroke: $color-secondary;
                    cursor: pointer;
                }
            }
        }

        &__content {
            padding: $modal-padding;
            width: 100%;

            .form__fields {
                label {
                    display: block;
                    color: $color-secondary;
                    margin-bottom: $size-sm;
                }
                .radio-select {
                    &__top {
                        label {
                            margin: 0;
                        }
                    }
                }

                .phone-input__container {
                    margin-bottom: $size-lg;
                }
            }

            .MuiTabs-flexContainer {
                justify-content: center;
            }
        }

        p {
            text-align: center;
            margin-bottom: $size-md;

            &.primary {
                color: $color-primary;
            }

            &.font-xl {
                font-size: $font-xl;
            }

            &.warning-msg {
                margin-top: 0;
            }
        }

        .content-wrap {
            margin: 0 $size-md;
            text-align: center;
        }

        .MuiButtonBase-root.primary,
        .MuiButtonBase-root.primary:hover,
        .MuiButtonBase-root.secondary,
        .MuiButtonBase-root.secondary:hover {
            margin-top: $size-md;
        }
    }

    &.full-height {
        .MuiPaper-root {
            min-height: $full-height;
        }
    }
}
