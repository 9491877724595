/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.alert {
    flex-direction: column;

    .MuiAlert-icon {
        color: $color-secondary;
        svg {
            width: $svg-height-lg;
            stroke: $color-secondary;
        }
    }
    .MuiAlert-action {
        padding: 0;
        margin: 0;
    }
    button {
        &.MuiButtonBase-root.primary  {
            margin: $size-sm 0;
            padding: $brand-btn-slim-padding;
        }
    }
}

@media only screen and (min-width: $breakpoint-md) {
    .alert {
        align-items: center;
        flex-direction: row;
        .MuiAlert-message {
            max-width: 70%;
        }
        .MuiAlert-action {
            padding: 0 0 0 $size-md;
            margin-left: auto;
        }
    }
}
