/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.radio-select {
    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: $size-sm;

        .MuiButtonBase-root.basic-btn {
            color: $color-primary;
            text-decoration: underline;
            padding: $size-sm 0;

            &:hover,
            &:focus {
                background-color: transparent;
            }
        }
    }

    .MuiFormGroup-root {
        .radio-select__item {
            border: $base-border;
            border-radius: $modal-border-radius;
            margin-bottom: $size-md;

            &__label {
                display: flex;
                align-items: center;
                > div {
                    display: flex;
                    flex-direction: column;
                    .error {
                        color: $color-error;
                        font-size: $font-sm;
                    }
                }
            }

            .MuiFormControlLabel-root {
                display: flex;
                padding: $size-sm;
                margin: 0;

                .MuiTypography-root {
                    display: flex;
                    margin-left: $size-xs;

                    svg {
                        stroke: $color-secondary;
                        margin-right: $size-sm;
                        width: $svg-height-lg;
                    }
                }
            }

            &.filled {
                .MuiTypography-root {
                    svg {
                        path {
                            fill: $color-secondary;
                        }
                    }
                }
            }

            &.selected:not(.disabled) {
                border-color: $color-primary;
            }
            &.disabled {
                .MuiFormControlLabel-root {
                    .MuiTypography-root {
                        svg {
                            stroke: $color-grey-neutral-200;
                        }
                    }
                }

                &:first-child {
                    .MuiTypography-root {
                        svg {
                            stroke: none;

                            path {
                                fill: $color-grey-neutral-200;
                            }
                        }
                    }
                }
            }
        }
    }

    .show-more-btn {
        text-decoration: underline;
    }

    .disable-warning {
        font-size: $font-sm;
        margin-top: calc($size-sm * -1);
        display: block;
    }
}
