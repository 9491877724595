/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.signer-action-bar {
    padding: 0 $size-xl;
    width: 100%;
    background-color: $color-white;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    color: $color-grey-neutral-900;
    font-size: $font-sm;
    border-top: solid $spacing-xxs $color-yellow-todo-300;

    button {
        background-color: transparent;
        border: none;
    }

    &__name-container {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
    }

    svg {
        stroke: $color-grey-neutral-900;
        height: $svg-height-xl;
        width: auto;
    }  

    &__cancel {
        svg {
            stroke: $color-red-500;
        }
    }

    &__success {
        svg {
            stroke: $color-success;
        }
    }
}