/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.checkbox-form-group {
    margin-bottom: $size-lg;

    &__top {
        &__label {
            text-align: start;
            margin-bottom: $size-sm;
            display: block;
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        gap: $size-sm;

        &.infinite-scroll-wrapper {
            max-height: $max-height-filter-checkbox;
            overflow-y: auto;
            padding: 0;
        }
    }
}
