/**
 *
 * @Copyright 2025 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

 .signer-form {
   
    .back-button {
        margin: $size-md 0;
        margin-left: -$size-xxs;
        padding-left: 0;
    }
}