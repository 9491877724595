/**
*
* @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
* Development by VOID Software, SA
*
*/

.signature-availability {
    padding: $default-page-padding;

    &__header {
        display: flex;
        align-items: center;
        margin-left: -$size-md;

        button {
            margin-right: $size-sm;

            svg {
                stroke: $color-secondary;
            }
        }
        h1 {
            font-size: $font-xl;
        }
    }
    &__content {
        &__item {
            > ul {
                list-style: none;
                margin: 0 0 $size-xl $size-xxs;
                padding: 0;

                > li {
                    border-bottom: $base-border;
                    margin-bottom: $size-md;

                    p {
                        text-align: left;
                    }

                    &:last-child {
                        border: none;
                    }

                    ul {
                        padding: 0 0 $size-md $size-xl;
                        list-style-type: disc;

                        li {
                            line-height: 1.5;
                        }
                    }
                }
            }

            &__country {
                display: flex;
                align-items: center;
                margin-bottom: $size-xxs;

                &__flag {
                    font-size: $svg-height-lg;
                    margin-right: $size-sm;
                }

                h4 {
                    margin: 0;
                }
            }
        }
    }
}
