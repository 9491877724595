/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

 .esignatures-layout {
    display: grid;
    grid-template-rows: auto 1fr auto; 
    grid-template-areas:
    'header header'
    'pagearea pagearea'
    'footer footer';
    height: 100dvh;

    &__header {
        grid-area: header; 
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: $esignatures-layout-padding;
        background-color: $color-yellow-todo;

        &__logo-wrap {
            width: auto;
            height: 25px;
            margin-right: 8px;

            svg {
                width: auto;
                height: 25px;
            }
        }
        
        &__icon-wrap {
            display: flex;
            align-items: center;

            svg {
                stroke: $color-primary;
                fill: $color-yellow-todo;
                
                path {
                    stroke: $color-primary;
                    fill: $color-yellow-todo;
                    stroke-width: 1.2;
                }
            }
        }
       
    }

    &__footer {
        grid-area: footer; 

        &__btns-wrap {
            background-color: $color-white;
            padding: 9px 3px;
            display: flex;
            justify-content: center;
            border-top: 1px solid $color-grey-neutral-100;
        
            .MuiButtonBase-root.custom-btn {
                margin: 0;
                padding: 15px 30px;
                max-width: 200px; 
                width: 49%;

                .btn-content {
                    display: flex;
                    align-items: center;

                    svg {
                        height: auto;
                        width: 12px;
                    }
                }

                &:first-child {
                    .btn-content {
        
                        svg {
                            margin-right: 8px;
                            stroke: $color-secondary;
                        }
                    }
                }
                
                &:last-child {
                    .btn-content {
    
                        svg {
                            margin-left: 8px;
                            stroke: $color-white;
                        }

                        
                    }
                }

                &.Mui-disabled {
                    color: inherit;
                    opacity: .5;
                }
            }
        }
    }

    &__content{
        grid-area: pagearea; 
        overflow: auto;
    }
 }


