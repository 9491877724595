/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.billing-debit-movements-screen {
    width: 100%;
    height: 100%;
    padding: $default-page-padding;

    &__header {
        &__title {
            display: flex;
            justify-content: space-between;

            svg {
                stroke: #000;
            }

            h1 {
                width: max-content;
            }
        }

        h1 {
            font-size: $font-xxl;
        }

        .MuiTabs-root {
            border-bottom: $base-border;
            margin-bottom: $spacing-md;
        }
    }

    &__content {
        max-height: 100%;

        &__header {
            display: none;
        }

        &__filters {
            display: none;
        }

        &__list {
            display: flex;
            flex-direction: column;
            gap: $spacing-md;
            padding: 0;

            &__item {
                border: $base-border;
                border-radius: $basic-btn-border-radius;
                padding: $spacing-sm $spacing-md;
                display: grid;
                grid-template: 1fr 1fr 1fr/ 1fr 1fr;
                justify-content: start;
                row-gap: $spacing-lg;
                column-gap: $spacing-sm;

                h4,
                p {
                    margin: 0;
                }

                h4 {
                    font-size: $font-md;
                }

                p {
                    font-size: $font-lg;
                }

                &__value {
                    text-align: end;

                    &[data-amount]:not([data-amount="0"]) {

                        &[data-operation="credit"] p {
                            color: $color-success;
                            &::before {
                                content: "+ ";
                            }
                        }
    
                        &[data-operation="debit"] p {
                            color: $color-red-600;
                            &::before {
                                content: "- ";
                            }
                        }
                    }
                }

                &__status {
                    &[data-status="COMPLETED"] p {
                        color: $color-success;
                    }

                    &[data-status="PENDING"] p{
                        color: $color-yellow-todo-500;
                    }

                    &[data-status="CANCELED"] p{
                        color: $color-red-600;
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: $breakpoint-md) {
    .billing-debit-movements-screen {
        &__header {
            &__title {
                .circle-btn {
                    display: none;
                }
            }
        }
        &__content {
            position: relative;

            &__filters {
                display: unset;

                &__filter {
                    display: flex;
                    flex-wrap: nowrap;
                    column-gap: $spacing-sm;
                    row-gap: $spacing-sm;

                }
            }

            &__header {
                position: sticky;
                top: 0;
                display: grid;
                grid-template-columns: 1fr 1fr 2fr 1fr 1fr;
                grid-template-rows: 1fr;
                background: $color-white;
                gap: $size-sm;

                :last-child {
                    text-align: end;
                }
            }

            &__list {
                &__item {
                    border: unset;
                    border-radius: unset;
                    border-bottom: $base-border;
                    padding: unset;
                    padding-bottom: $spacing-md;
                    display: grid;
                    gap: $size-sm;
                    grid-template-columns: 1fr 1fr 2fr 1fr 1fr;
                    grid-template-rows: unset;

                    h4 {
                        display: none;
                    }
                }
            }
        }
    }
}
