/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.subscription-dashboard-screen,.wallet-screen  {
    padding: $size-lg;
    width: 100%;

    h1 {
        margin-bottom: $size-lg;
    }
    &__usage {
        margin-top: $size-lg;
    }
    .loading-circles {
        margin-top: $size-lg;
    }
    > p{
        a {
            margin-left: $size-xs;
        }
    }
}