/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.billing-information-form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    h2 {
        margin-bottom: $size-md;
    }

    .separator {
        margin: $size-lg 0 $size-sm;
    }

    .MuiGrid-root {
        > .MuiGrid-item {
            padding-top: $size-lg;

            .form-field__container__input-wrapper {
                margin-bottom: 0;

                .address-line2 {
                    margin-top: -$size-sm;
                }
            }
        }
    }
}
