/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.horizontal-stepper {
    .MuiStep-horizontal {
        .MuiStepConnector-horizontal {
            &.Mui-active > span,
            &.Mui-completed > span {
                border-color: $color-primary;
            }
        }

        .MuiStepLabel-horizontal {
            .Mui-active > svg,
            .Mui-disabled > svg {
                text {
                    display: none;
                }
            }

            .MuiStepLabel-iconContainer.Mui-active {
                position: relative;

                &:after {
                    content: "";
                    position: absolute;
                    transform: translate(-50%, -50%);
                    top: 50%;
                    left: 50%;
                    width: 30%;
                    height: 30%;
                    background-color: $color-white;
                    z-index: 1;
                    border-radius: $border-circle;
                }
            }
        }
    }

    @media only screen and (max-width: $breakpoint-sm) {
        &.big-stepper {
            .MuiStep-horizontal {
                .MuiStepLabel-horizontal {
                    .MuiStepLabel-labelContainer {
                        display: none;
                    }
                }
            }
        }
    }
}
