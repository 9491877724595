/**
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.checkbox {
    display: flex;
    align-items: center;

    &--error {
        input {
            border: $error-border !important;
        }
    }
}

input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    width: $size-md;
    height: $size-md;
    border-radius: 18%;
    margin: 0 0.5rem 0 0;
    border: $primary-border;
    background-color: rgba($color-grey-neutral-100, 0.3);
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:disabled {
        border-color: $color-grey-neutral-200;
        background-color: rgba($color-grey-neutral-100, 0.3);
        cursor: not-allowed;
    }

    &:checked {
        background-color: $color-primary;

        &::before {
            transform: translate(-50%, -50%) scale(1);
        }
    }

    &::before {
        content: "";
        width: 50%;
        height: 50%;
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0);
        transform-origin: center;
        transition: transform 0.2s ease-in-out;
        box-shadow: inset 1em 1em $color-white;
        background-color: transparent;
    }

    &.md {
        width: $size-md;
        height: $size-md;
        min-width: $size-md;
        min-height: $size-md;
    }

    &.lg {
        width: $size-lg;
        height: $size-lg;
        min-width: $size-lg;
        min-height: $size-lg;
    }

    &.xl {
        width: $size-xl;
        height: $size-xl;
        min-width: $size-xl;
        min-height: $size-xl;
    }

    &.xxl {
        width: $size-xxl;
        height: $size-xxl;
        min-width: $size-xxl;
        min-height: $size-xxl;
    }

    &.xxxl {
        width: $size-xxxl;
        height: $size-xxxl;
        min-width: $size-xxxl;
        min-height: $size-xxxl;
    }
}
