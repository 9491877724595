/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.workflows-screen,
.organization-workflows-screen {
    padding: $size-lg;
    width: 100%;
    height: 100%;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .large-add-btn {
            display: none;
        }

        .filters-trigger-btn {
            stroke: $color-secondary;

            svg {
                stroke: inherit;
                width: $svg-height-lg;
                height: auto;
            }
        }

        &__order {
            display: none;
        }
    }

    &__applied-filters {
        display: none;
    }

    &__content {
        margin-top: $size-sm;
        max-height: 100%;

        &__large-filters {
            display: none;
        }

        &__list {
            margin-top: 0;
            max-height: 100%;

            .BETA-migration-warning {
                color: $color-red-300;
                margin-top: 0;
            }

            &__empty-list {
                padding: 0 $size-lg;
            }

            .infinite-scroll-wrapper {
                max-height: calc(100vh - 165px);
                padding: 0;
            }

            a {
                text-decoration: none;
            }
        }
    }
}
.add-document-modal {
    label {
        margin-top: $size-lg;
    }

    &__warning {
        font-size: $font-sm;
        opacity: 0.5;
        margin: $size-xs 0 0 0;
    }
}
@media only screen and (min-width: $breakpoint-md) {
    .workflows-screen,
    .organization-workflows-screen {
        display: grid;
        grid-template-areas:
            "header"
            "filters"
            "content";
        grid-template-rows: auto auto 1fr; /* Cabeçalho e filtros com altura automática, conteúdo ocupa o restante */
        height: 100%;

        &__header {
            grid-area: header;
            align-items: flex-start;

            .large-add-btn {
                display: block;
            }
            .filters-trigger-btn {
                display: none;
            }
        }

        &__applied-filters {
            grid-area: filters;
            display: flex;
            flex-direction: column;

            &__header {
                button {
                    margin-left: $size-sm;
                    text-decoration: underline;
                }
            }

            &__list {
                margin: $size-sm 0 $size-xl;

                &__item.MuiButtonBase-root {
                    margin-right: $size-sm;
                    margin-bottom: $size-xs;
                    stroke: $color-secondary;

                    svg.MuiChip-deleteIcon {
                        width: $svg-height-md;
                        height: auto;
                    }
                }
            }
        }

        &__content {
            grid-area: content;
            display: grid;
            grid-template-columns: 300px 1fr;
            gap: $size-lg;
            max-height: 100%;
            overflow: hidden;

            &__large-filters {
                display: block;
                border: $base-border;
                border-radius: $card-border-radius;
                box-shadow: none;
                max-height: 100%;
                overflow: hidden;
            }

            &__list {
                max-height: 100%;
                overflow: auto;
            }
        }
    }

    .filters-drawer {
        display: none;
    }
}

@media only screen and (min-width: $breakpoint-xl) {
    .workflows-screen,
    .organization-workflows-screen {
        &__content {
            grid-template-columns: 350px 1fr;
        }

        .add-btn-wrap {
            display: none;
        }
    }
}
