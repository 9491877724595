/**
*
* @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
* Development by VOID Software, SA
*
*/

.add-participant-modal {
    .radio-group {
        width: 100%;

        .form-field__top {
            display: block;
        }
    }
    .divider {
        width: 100%;
        border: $base-border;
        margin-bottom: $spacing-lg;
    }
}

@media only screen and (min-width: $breakpoint-xs) {
    .add-participant-modal {
        .MuiTabs-flexContainer {
            button {
                width: 50%;
            }
        }
    }
}