/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.members-screen {
    width: 100%;
    padding: $size-lg;

    &__header {
        padding: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        a {
            text-decoration: none;
        }
        .large-add-btn {
            display: none;
        }
    }

    &__wrap {
        min-width: 100%;
        max-width: 100%;

        &__filters {
            display: grid;
            margin-bottom: $size-lg;
            grid-template-columns: repeat(4, 1fr);

            button {
                padding: $size-sm 0;
                border: 0;
                background-color: transparent;
                color: $color-grey-neutral-700;
                font-family: inherit;
                font-size: $font-sm;
                text-transform: capitalize;

                &.selected {
                    border-bottom: $primary-border-2;
                    color: $color-primary;
                }
            }
        }

        .infinite-scroll-wrapper {
            padding: 0;
            max-height: calc(100vh - 165px);
        }
    }
}

@media only screen and (min-width: $breakpoint-md) {
    .members-screen {
        &__header {
            .large-add-btn {
                display: block;
            }
        }
    }
    .add-btn-wrap {
        display: none;
    }
}
