/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.innovators-club-section {
    padding: $size-lg $size-xl;
    background: $gradient-clear-purple;
    border-radius: $basic-btn-border-radius;

    h2 {
        font-size: $font-xl;
    }

    button a {
        color: $color-white;
        text-decoration: none;
    }
}

@media only screen and (min-width: $breakpoint-md) {
    .innovators-club-section {
        > .MuiGrid-root {
            flex-direction: row-reverse;
            align-items: center;

            .image-wrapper {
                svg {
                    float: right;
                }
            }
            h2 {
                font-size: $font-innovators-title;
            }
            p {
                font-size: $font-lg;
            }
        }
    }
}
