/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.manage-participant-modal {
    .permissions-card-list {
        border-radius: $basic-btn-border-radius;
        box-shadow: $workflow-button-shadow;
        padding: 0;
        max-height: 500px;

        max-height: unset;
        margin: 0 0 $size-xl;
        width: 100%;

        .permissions-card {
            display: inline-grid;
            grid-template-columns: auto max-content;
            width: 100%;
            padding: $size-md;
            position: relative;

            border: $base-border;
            border-radius: $basic-btn-border-radius;
            align-items: center;

            // add a pseudo element with border bottom to any card element that is not last element
            &:not(:last-of-type) {
                &::after {
                    content: "";
                    width: 100%;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    border-bottom: $base-border;
                }
            }

            // reset bottom radiuses and border-bottom if card is the 1st in a list
            &:first-of-type:not(:only-child) {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;

                border-bottom: none;
            }

            // reset top radiuses and border-top if card is the last in a list
            &:last-of-type:not(:only-child) {
                border-top-left-radius: 0;
                border-top-right-radius: 0;

                border-top: none;
            }

            // reset radiuses and top/bottom border if card is the middle of a list
            &:not(:first-of-type):not(:last-of-type) {
                border-radius: 0;

                border-bottom: none;
                border-top: none;
            }
            
            &__info {
                p {
                    margin: $size-xxs 0; 
                    text-align: left;
                }
            }
            .MuiFormControl-root {
                select {
                    border: none;
                    color: $color-primary;
                    text-align: right;
                    padding-right: $svg-height-xl;
                    -webkit-appearance: none;
                    appearance: none;
                    background-image: url(../../../images/arrow-down-broken-purple.svg);
                    background-repeat: no-repeat;
                    background-position: right 0 top 50%;
                    background-size: $svg-height-lg auto;
                    font-family: $font-primary;

                    &:focus, &:hover {
                        outline: none;
                    }
                }
            }

        }
    }
    .permissions__bottom-message {
        p {
            font-size: $font-sm;
            text-align: left;
            margin-bottom: 0;
        }
        a {
            font-size: $font-sm;
            margin-bottom: $size-sm;
        }
    }
}