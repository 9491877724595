/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

 .upload-document-card {
    display: inline-grid;
    grid-template-columns: auto max-content;
    width: 100%;
    padding: $upload-document-card-padding;
    position: relative;
    background-color: $color-grey-neutral-50;

    border: 1px dashed $color-grey-neutral-300;
    border-radius: $basic-btn-border-radius;

    cursor: pointer;

    &__info {
        width: 100%;
        display: inline-grid;
        grid-template-columns: max-content auto;
        align-items: center;
        gap: $spacing-lg;

        &__icon-wrap {
            height: $svg-height-lg;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                opacity: 1;
                height: 100%;
                width: auto;
                stroke: $color-secondary;
            }
        }

        &__details {
            overflow: hidden;

            p, h1 {
                margin: 0;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                padding: $size-xxs;
            }

            h1 {
                font-size: $font-md;
                line-height: $font-md;
                margin-bottom: $size-xxs;
            }

            &__status {
                font-size: $font-sm;
                line-height: $font-sm;
                color: $color-grey-neutral-500;
                padding: $size-xxs 0;
                width: max-content;
            }

            &__loading {
                display: inline-block;
                width: 100%;
                height: 10px;
                background-color: $color-primary;
                border-radius: 10px;
                animation: loading-bar ease-in 1s;
                position: relative;
                overflow-x: hidden;

                &::after {
                    content: '';
                    height: 100%;
                    width: 70px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: linear-gradient(to right, #ffffff00, $color-brand-300, #ffffff00);
                    animation: sweeping-effect $cubic-bezier-loading 1s infinite;
                }
            }
        }
    }

    .MuiButton-root {
        padding: 0 $size-md;
        min-width: unset;
        border-radius: $border-circle;
    }

    input {
        display: none;
    }
}

.upload-document-card-list {
    border-radius: $basic-btn-border-radius;
    padding: 0;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    gap: $spacing-xs;
}

@keyframes loading-bar {
    0% {
        width: 0%;
    }

    100% {
        width: 100%;
    }
}

@keyframes sweeping-effect {
    0%   {
        left: calc(0% - 70px);
    }

    100% {
        left: calc(100% + 70px);
    }
}
