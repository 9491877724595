/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.billing-information-screen {
    width: 100%;

    .step-content__screen {
        width: 100%;
        display: grid;
        grid-template-columns: auto 40%;
        grid-gap: $spacing-lg;
    }

    .checkout-summary {
        margin-top: $size-xxl;
    }
}

@media only screen and (max-width: $breakpoint-md) {
    .billing-information-screen {
        .step-content__screen {
            display: block;
        }

        h2 {
            margin: $size-sm 0 $size-md;
        }
    }
}

@media only screen and (min-width: $breakpoint-md) {
    .billing-information-screen {
        .step-content__screen {
            margin: 0 $size-xl;
            width: calc(100% - $size-xxxl);
        }
    }
}
