/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.esignature-loading-modal {
    padding: 0;
    border: none;

    &__container {
        background-color: $color-primary;
        position: relative;
        padding: 3rem 2rem 2rem;
        max-width: 300px;
        display: grid;
        gap: 1rem;

        h2 {
            text-align: center;
            color: $color-white;
            font-size: $font-xl;
        }
        
        p {
            margin-top: 0;
            display: inline;
            text-align: center;
            color: $color-white;
            opacity: 0.6;
        }
        a{
            text-decoration: underline;
            color: $color-white;
            text-align: center;
            opacity: 0.6;
        }
    }
    &__icons {
        display: flex;
        justify-content: center;
        align-content: center;
        gap: 1rem;
    }

    &::backdrop {
        background-color: $color-white;
        opacity: 0.9;
    }
    
}
@media only screen and (min-width: $breakpoint-lg) {
    .esignature-loading-modal {

        &__container {
            max-width: $breakpoint-xs;
        }
    }
}