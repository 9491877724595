/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.purchase-credits-screen {
    padding: $size-lg;
    width: 100%;
    display: grid;
    grid-template-columns: auto 40%;
    grid-gap: $spacing-md;
    align-items: start;

    &__container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;

        > button {
            background: transparent;
            border: none;
            text-decoration: underline;
        }

        &__input-form-group {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;

            p {
                white-space: break-spaces;
                color: $color-grey-neutral-700;
            }

            .buttons-container {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: $spacing-sm;

                .MuiButtonBase-root {
                    background-color: $color-brand-50;
                    color: $color-primary;
                }
            }

            .form-field {
                width: 33%;
                align-self: center;

                &__container {
                    &__input-wrapper {
                        justify-content: center;
                        input,
                        input:focus {
                            border-top-width: 0;
                            border-left-width: 0;
                            border-right-width: 0;
                            border-radius: 0;
                            outline: none;

                            text-align: center;
                        }
                    }
                }
            }
        }
        &__links {
            display: flex;
            gap: $size-md;
        }
    }
}
