/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.cmd-signature-banner {
    width: 100%;
    text-align: center;
    background-color: $color-cmd;
    padding: $size-xs 0;
}

.cmd-context {
    padding: $default-page-padding;
}

.cmd-heading {
    color: $color-primary;
}

.cmd-stepper {
    display: flex;
    justify-content: center;
}

#cmd-commitments-zone {
    ol {
        padding: 0;
    }
}