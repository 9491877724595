/*
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.share-component {
    &__modal {
        h4 {
            margin-top: $size-xl;
        }
        &__share-info {
            background-color: $color-grey-neutral-50;
            border-radius: $basic-btn-border-radius;
            padding: $spacing-md;
            display: flex;
            align-items: center;
            margin-bottom: $spacing-lg;

            >svg {
                min-width: $logo-svg-height;
                min-height: $logo-svg-height;
                margin-right: $spacing-md;
            }
            >div {
                overflow: hidden;
                font-size: $font-sm;
            }
            &__text__url {
                color: $color-primary;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

        }
        &__share-options {
            display: flex;
            justify-content: center;
            border-bottom: $base-border;
            margin-bottom: $size-md;
            padding-bottom: $size-xl;
            gap: $size-lg;
            flex-wrap: wrap;
            >a {
                text-decoration: none;
                color: $color-secondary;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            .MuiButtonBase-root.icon-btn {
                width: $icon-btn-size;
                height: $icon-btn-size;
                margin-top: 0;
                transition: $svg-transition;
 
                &.whatsapp {
                    background-color: $whatsapp-color;

                    svg {
                        stroke: none;
                        fill: $color-white;
                    }
                }
                &.telegram {
                    background-color: $telegram-color;

                    svg {
                        stroke: none;
                        fill: $color-white;
                    }
                }
                &.email, &.QRCode {
                    svg {
                        fill: none;
                        stroke: $color-secondary;
                    }
                }
            }
            .MuiButtonBase-root.icon-btn:hover {
                margin-top: 0;
                width: $icon-btn-size;
                height: $icon-btn-size;

                &.whatsapp {
                    background-color: darken($whatsapp-color, 5%);
                }
                &.telegram {
                    background-color: darken($telegram-color, 4%);
                }
            }
            &__copy__wrapper {
                position: relative;
            }
        }
    }
}
.qrcode-invite {
    margin: 0 auto;
    width: $qrcode-size;
    height: $qrcode-size;
    svg {
        width: 100%;
        height: 100%;
    }
    &__modal {
        .modal-confirm__wrapper{
            width: $qrcode-modal-size;
        }
    }
}
@media only screen and (min-width: $breakpoint-xs){
    .share-component {
        &__modal {
            &__share-options {
                justify-content: flex-start;
            }
        }
    }
}

@media only screen and (min-width: $breakpoint-md){
    .share-component {
        &__modal {
            &__share-info {
                >svg {
                    min-width: $icon-btn-size;
                    min-height: $icon-btn-size;
                }
            }
            &__share-options {
                display: flex;
                border-bottom: $base-border;
                margin-bottom: $size-md;
                padding-bottom: $size-xl;

                .MuiButtonBase-root.icon-btn,.MuiButtonBase-root.icon-btn:hover {
                    width: $icon-btn-large;
                    height: $icon-btn-large;
                }
            }
        }
    }
}