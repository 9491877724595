/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

 .currency-input {
    margin-bottom: $size-lg;
    
    label {
        font-size: $font-md;
        line-height: 1.4;
        display: inline-block;
        margin-bottom: $spacing-xs;
    }

    .currency-input-container {
        background: transparent;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
    
        border: $base-border;
        border-radius: $input-border-radius;
        width: 100%;
    
        font-size: $font-md;
        line-height: 1.5;
        padding: $currency-input-padding;

        input {
            width: calc(100% - 60px); // 60px is currency max-width
            background: transparent;
            border: none;
            font-family: $font-primary;
    
            &:focus {
                outline: none;
            }
        }
    
        .form-field {
            width: unset;
    
            &__top {
                display: none;
            }
    
            &__container {
                &__input-wrapper {
                    margin-bottom: 0;
                    
                    select {
                        border: none;
                        padding: unset;
                        padding-right: $spacing-lg;
                        background-position: right 0 top 50%;
                    }
                }
            }
        }    
    }
    &:hover {
        .currency-input-container {
            border-color: $color-grey-neutral-300;
        }
    }

    &:focus-within {
        .currency-input-container {
            outline: $primary-border;
            border: $primary-border;
        }
    }
}
