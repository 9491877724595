/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.add-credits-screen {
    h2 {
        margin-bottom: $size-md;
    }

    &__container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        p {
            white-space: break-spaces;
            color: $color-grey-neutral-700;
        }

        .buttons-container {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: $spacing-sm;

            .MuiButtonBase-root {
                background-color: $color-brand-50;
                color: $color-primary;
            }
        }

        .form-field {
            width: 33%;
            align-self: center;

            &__container {
                &__input-wrapper {
                    justify-content: center;
                    input,
                    input:focus {
                        border-top-width: 0;
                        border-left-width: 0;
                        border-right-width: 0;
                        border-radius: 0;
                        outline: none;

                        text-align: center;
                    }
                }
            }
        }

        &__info {
            margin-top: $size-lg;
            width: 90%;
            padding: $spacing-lg $spacing-md;
            border: $base-border;
            border-radius: $basic-btn-border-radius;

            > * {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: $spacing-md;
            }
        }
    }
    .step-content__screen {
        width: 100%;
        display: grid;
        grid-template-columns: auto 40%;
        grid-gap: $spacing-md;
    }
}

@media only screen and (max-width: $breakpoint-md) {
    .add-credits-screen {
        .step-content__screen {
            display: block;
        }

        h2 {
            margin: $size-sm 0 $size-md;
        }
    
        &__container {
            &__info {
                width: 100%;
            }
        }
    }
}
@media only screen and (min-width: $breakpoint-md) {
    .add-credits-screen {
        .step-content__screen {
            margin: 0 $size-xl;
            width: calc(100% - $size-xxxl);
        }
    }
}
