/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.submit-contract-summary-screen {
    background-color: $color-white;
    padding: $default-page-padding;
    max-width: $breakpoint-md;
    margin: 0 auto;

    &__header {
        button {
            margin-left: -$size-sm;
            margin-right: $size-sm;
            display: flex;
            align-items: center;
            svg {
                stroke: $color-primary;
                margin-right: $size-sm;
            }
        }
        h1 {
            font-size: $font-lg;
            margin-bottom: $size-xl;
            text-align: center;
        }
    }
   
    &__billing {  
        font-size: $font-sm;
        padding: $size-md 0;
        margin-top: 0;

        li {
            display: flex;
            justify-content: space-between;
            padding: $size-md 0;
            border-top: $base-border;

            &:first-child,&:nth-child(2),&:last-child {
                border-top:0;
            }
            &.submit-contract-summary-screen__billing__header {
                padding-bottom: 0;
                font-size: $font-md;
            }
            &.submit-contract-summary-screen__billing__total {
                border-top: $primary-border-2;
                font-size: $font-md;
            }
        }
    }

    .alert {
        margin: $size-lg 0 0 0;
        button {
            margin: $size-xxs;
            margin-left:-$size-sm;
        }
    }
    .btn-wrapper {
        text-align: center;
    }
}

@media only screen and (min-width: $breakpoint-lg){
    .submit-contract-summary-screen  {
        border: $base-border;
        border-radius: $basic-btn-border-radius;
        padding: $size-xxl;
        margin: $size-xxl auto;
    } 
}
@media only screen and (min-width: $breakpoint-xl){
    .submit-contract-summary-screen  {
        h1 {
            font-size: $font-xl;
        }
    } 
}
