/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.filters-form {
    &__filters {
        padding: $size-lg;

        .separator {
            margin: 0 0 $size-md;
        }

        .radio-select {
            margin-bottom: $size-md;

            .MuiFormGroup-root {
                .radio-select__item {
                    border: none;
                    margin-bottom: 0;
                    margin-left: -$size-sm;
                    label {
                        padding: 0;
                    }
                }
            }
            .show-more-btn {
                margin-left: -$size-xs;
            }
        }
    }

    &__buttons {
        position: sticky;
        bottom: 0;
        background-color: $color-white;
        display: flex;
        justify-content: space-between;
        border-top: $base-border;
        padding: 0 $size-lg;
        box-shadow: $sidebar-shadow;

        button.MuiButtonBase-root,
        button.MuiButtonBase-root:hover {
            width: 49%;
            margin-bottom: $size-md;
            padding: $brand-btn-shorter-padding;
        }
    }

    .infinite-scroll-wrapper {
        max-height: $max-height-filter-checkbox;
        overflow-y: auto;
        padding: 0;
    }
}

.filters-drawer {
    .MuiPaper-root {
        padding: 0;

        .drawer__wrapper__content {
            padding: 0;
        }
    }
}

@media only screen and (min-width: $breakpoint-md) {
    .filters-form {
        max-height: 100%;
        overflow: hidden;
        display: grid;
        grid-template-rows: 1fr auto;
        grid-template-areas:
            "filters"
            "buttons";
        height: 100%;

        &__filters {
            grid-area: filters;
            padding: $size-md;
            height: 100%;
            overflow: auto;
        }

        &__buttons {
            grid-area: buttons;
            padding: $size-sm $size-md;

            button.MuiButtonBase-root,
            button.MuiButtonBase-root:hover {
                margin: $size-sm 0;
                text-transform: none;
                padding: $size-md;
                border-radius: $basic-btn-border-radius;
                width: 100%;
            }
            .clean-btn {
                display: none;
            }
        }
    }
}
