/*
 *
 * @Copyright 2023 UNLOCK DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

.form-field {
    &__top {
        width: 100%;
        display: inline-flex;

        label {
            color: $color-secondary;
            margin-bottom: $size-sm;
        }
    }

    &__container {
        display: flex;
        align-items: center;
        justify-content: center;

        &__input-wrapper {
            border: none;
            width: 100%;
            transition: $input-transition;
            position: relative;
            background-color: $color-white;
            padding: 0;
            box-shadow: none;
            margin-bottom: $size-lg;

            textarea {
                resize: vertical;
            }

            input,
            textarea {
                border: $base-border;
                border-radius: $input-border-radius;
                padding: $input-padding;
                width: 100%;
                height: 100%;
                outline: none;
                background-color: $color-white;
                font-family: $font-primary;
                transition: ease-out 500ms;
                color: $color-secondary;

                &:hover:not(:disabled) {
                    border-color: $color-grey-neutral-300;
                }

                &:focus {
                    outline: $primary-border;
                    border: $primary-border;
                }

                &.bg-gray-filled {
                    background-color: $color-grey-neutral-50;
                    border-color: $color-grey-neutral-50;
                }
            }

            select {
                border: $base-border;
                border-radius: $input-border-radius;
                padding: $select-padding;
                width: 100%;
                font-size: $font-md;
                background-color: $color-white;
                outline: none;
                font-family: $font-primary;
                color: $color-secondary;
                -webkit-appearance: none;
                appearance: none;
                background-image: url(../../images/arrow-down.svg);
                background-repeat: no-repeat;
                background-position: right 1rem top 50%;
                background-size: 14px auto;

                &:hover:not(:disabled) {
                    border-color: $color-grey-neutral-300;
                }

                &:focus {
                    outline: $primary-border;
                    border: $primary-border;
                }

                &:disabled {
                    opacity: 0.5;
                }
            }

            &__prefix {
                position: absolute;
                top: -$spacing-md;
                height: 100%;
                display: inline-flex;
                align-items: center;
                font-size: $font-md;
                color: $color-secondary;
            }

            .input-prefixed {
                padding-left: $size-xxxl;
            }
        }

        &__helperText {
            font-size: $font-xs;
            margin-top: $size-xxs;
        }

        .disable-warning {
            font-size: $font-sm;
            margin-top: $size-xxs;
            display: block;
        }
    }
}
